import store from "../../../store";
import PostServiceApi from "../../services/api/post-service";
import { NOTIFICATION_OPTIONS } from "../../static-data";
import { setAppSnackNotification } from "../../store/actions/application";
import AppLogger from "../../utils/logger";

const formatNotificationOptions = ({ options = [] }) => {
  let output = [];
  options.forEach((element) => {
    const option = NOTIFICATION_OPTIONS.find(
      (option) => option.id === element.option.id
    );
    if (option) {
      const temp = { ...option, isDefault: element.isDefault ?? false };
      output.push(temp);
    }
  });
  return output;
};

const geneateFormDropdownInitialValues = ({ types }) => {
  let output = {};
  try {
    const { authAccount } = store.getState().application;
    const pushNotificationPreferences =
      authAccount.pushNotificationPreferences ?? [];
    const tempMap = new Map();
    for (const current of pushNotificationPreferences) {
      tempMap.set(current.notification.id, current.option.id);
    }
    for (const current of types) {
      let initialValue = tempMap.get(current.id);
      if (!initialValue) {
        const defaultValue = current.options.find((temp) => temp.isDefault);
        if (defaultValue) {
          initialValue = defaultValue.id;
        }
      }
      output[current.id] = initialValue;
    }
  } catch (e) {}
  return output;
};

const getPushNotificationAllowedTypes = async () => {
  try {
    const response = await PostServiceApi.getPushNotificationAllowedTypes();
    if (!response.success) {
      throw new Error("Unable to get push notificaton types");
    }
    const ignoredTypes = [
      "sub_general",
      "sub_critical",
      "promo_general",
      "promo_critical",
    ];
    const responseData = (Array.isArray(response.body) ? response.body : [])
      .filter((element) => !ignoredTypes.includes(element.notification.id))
      .map((current) => ({
        id: current.notification.id,
        name: current.notification.name,
        options: formatNotificationOptions({ options: current.options ?? [] }),
      }));
    return responseData;
  } catch (e) {
    AppLogger.exception(e);
    store.dispatch(
      setAppSnackNotification({
        severity: "error",
        message: "Unable to get push notificaton types",
      })
    );
    throw e;
  }
};

const updatePushNotificationOption = async ({ typeId, optionId }) => {
  try {
    const response = await PostServiceApi.updatePushNotificationOption({
      typeId,
      optionId,
    });
    if (!response.success) {
      throw new Error("Unale to update notification type");
    }
    return response;
  } catch (e) {
    AppLogger.exception(e);
  }
};

const NotificationPreferenceDomain = {
  getPushNotificationAllowedTypes,
  geneateFormDropdownInitialValues,
  updatePushNotificationOption,
};
export default NotificationPreferenceDomain;
