import * as Yup from "yup";
import FormMeta from "../form-meta";

export const validationSchema = ({ t }) => {
  return Yup.object().shape({
    [FormMeta.nameOfRecipient.fieldName]: Yup.string()
      .required(
        t("errors.formRequiredField", {
          fieldName: t(FormMeta.nameOfRecipient.label),
        })
      )
      .test(
        "no-special-chars",
        t("errors.lettersSpacesDotsCommasOnly"),
        (value) => !/[`0-9!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/.test(value)
      ),
    [FormMeta.addressLineOne.fieldName]: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t(FormMeta.addressLineOne.label),
      })
    ),
    [FormMeta.addressLineTwo.fieldName]: Yup.string(),
    [FormMeta.city.fieldName]: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t(FormMeta.city.label),
      })
    ),
    [FormMeta.country.fieldName]: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t(FormMeta.country.label),
      })
    ),
  });
};
