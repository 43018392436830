import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import PageConfigContainer from "../../routes/configs/page-config-container";
import Config from "../../config";
import PageNotFound from "../../../pages/PageNotFound";
import AccessDeniedView from "./access-denied-view";
import Unauthorize from "../../../pages/Unauthorize";
import SplashView from "./splash-view";

const ROUTING_STATUS = Object.freeze({
  NOT_FOUND: "NOT_FOUND",
  ALLOWED: "ALLOWED",
  INITIALIZING: "INITIALIZING",
  AUTHENTICATION_REQUIRED: "AUTHENTICATION_REQUIRED",
  ACCESS_DENIED: "ACCESS_DENIED",
});

const { CURRENT_PLATFORM, ROUTE_ACCESS_LEVEL } = Config;

const RouteConfigHandler = ({ id, children }) => {
  const routeConfig = PageConfigContainer.getConfig({ id });
  const { initialized, authRouteAccess } = useSelector(
    (state) => state.application
  );

  const routingStatus = useMemo(() => {
    const supportedPlatforms = Array.isArray(routeConfig.platforms)
      ? routeConfig.platforms
      : [];
    if (!supportedPlatforms.includes(CURRENT_PLATFORM)) {
      return ROUTING_STATUS.NOT_FOUND;
    }
    if (!initialized) {
      return ROUTING_STATUS.INITIALIZING;
    }
    const allowedAccessLevels = Array.isArray(routeConfig.accessLevels)
      ? routeConfig.accessLevels
      : [];
    const securedRoute = !allowedAccessLevels.includes(
      ROUTE_ACCESS_LEVEL.PUBLIC_USER
    );
    if (securedRoute && authRouteAccess === ROUTE_ACCESS_LEVEL.PUBLIC_USER) {
      return ROUTING_STATUS.AUTHENTICATION_REQUIRED;
    }
    if (!allowedAccessLevels.includes(authRouteAccess)) {
      return ROUTING_STATUS.ACCESS_DENIED;
    }
    return ROUTING_STATUS.ALLOWED;
  }, [
    authRouteAccess,
    initialized,
    routeConfig.accessLevels,
    routeConfig.platforms,
  ]);

  const description = routeConfig.description;
  const title = routeConfig.title;
  const showSplashView = routeConfig.showSplashView ?? true;

  return (
    <>
      {(!isEmpty(title) || !isEmpty(description)) && (
        <Helmet>
          {!isEmpty(title) && <title>{title}</title>}
          {!isEmpty(description) && (
            <meta name="description" content={description} />
          )}
        </Helmet>
      )}
      {(routingStatus === ROUTING_STATUS.INITIALIZING && showSplashView && (
        <SplashView />
      )) ||
        (routingStatus === ROUTING_STATUS.NOT_FOUND && <PageNotFound />) ||
        (routingStatus === ROUTING_STATUS.ACCESS_DENIED && (
          <AccessDeniedView pageConfig={routeConfig} />
        )) ||
        (routingStatus === ROUTING_STATUS.AUTHENTICATION_REQUIRED && (
          <Unauthorize />
        )) ||
        children}
    </>
  );
};

export default RouteConfigHandler;
