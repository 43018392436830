import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Formik, useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { camelCase, isEmpty } from "lodash";

import FormDropdown from "../../../components/molecules/form-dropdown";
import { getAuthUserAccount } from "../../../features/application";
import NotificationPreferenceDomain from "../../../features/notification-preferences";
import { setAppSnackNotification } from "../../../store/actions/application";
import AppLogger from "../../../utils/logger";
import PageSection from "../page-section";

const Form = ({ notificationTypes }) => {
  const { values, setFieldValue } = useFormikContext();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [updatingId, setUpdatingId] = useState();

  const didChangedPreferences = useRef(false);

  const handleFieldChange = async (fieldName, event) => {
    const oldValue = values[fieldName];
    setFieldValue(fieldName, event.target.value);

    try {
      setIsSubmittingData(true);
      setUpdatingId(fieldName);
      const response =
        await NotificationPreferenceDomain.updatePushNotificationOption({
          typeId: fieldName,
          optionId: event.target.value,
        });

      if (response.success) {
        didChangedPreferences.current = true;
        dispatch(
          setAppSnackNotification({
            severity: "success",
            message: t("common.updatedsuccessfully"),
          })
        );
      }
    } catch (error) {
      setFieldValue(fieldName, oldValue);
      AppLogger.exception(error);
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: t("errors.errorOccured"),
        })
      );
    } finally {
      setIsSubmittingData(false);
    }
  };

  useEffect(() => {
    return () => {
      if (didChangedPreferences.current) {
        dispatch(getAuthUserAccount());
      }
    };
  }, [dispatch]);

  return (
    <Grid container direction="column" className="row-gap-24">
      {notificationTypes.map((element) => (
        <Grid container direction="column" xs={12} sm={6}>
          <Typography variant="body2">
            {t(`notificationPreferences.${camelCase(element.id)}`)}
          </Typography>
          <Grid container xs={12} md={8}>
            <Typography className="notification-type-caption">
              {t(`notificationPreferences.${camelCase(element.id)}_subtitle`)}
            </Typography>
          </Grid>
          <Grid item container className="margin-top-8" xs={12} md={8}>
            <FormDropdown
              fieldName={element.id}
              options={element.options}
              value={values[element.id]}
              labelExtractor="label"
              keyExtractor="id"
              lang={i18n.language}
              onChange={(event) => handleFieldChange(element.id, event)}
              loading={isSubmittingData && updatingId === element.id}
              disabled={isSubmittingData && updatingId === element.id}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const NotificationPreferences = ({ notificationTypes }) => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => {
    return NotificationPreferenceDomain.geneateFormDropdownInitialValues({
      types: notificationTypes,
    });
  }, [notificationTypes]);

  return (
    <PageSection
      heading={t("settingPage.notificationPreferences")}
      initialized={!isEmpty(notificationTypes)}
    >
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        <Form notificationTypes={notificationTypes} />
      </Formik>
    </PageSection>
  );
};

export default NotificationPreferences;
