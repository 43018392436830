import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import Config from "../../../v2/config";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { isEmpty } from "lodash";
import moment from "moment";

// import AcceptIntMsgModal from "../../interestModals/AcceptIntMsgModal";
import AlertModal from "./AlertModal";
import DisabledAlertModal from "./DisabledAlertModal";
import IgnoreModal from "./IgnoreModal";

import { removePost } from "../../../actions/removePost";
import { accordianSubTab } from "../../../actions/accordianSubTab";
import { fetchIntPostData } from "../../../actions/fetchIntPostData";
import { fetchAllInterest } from "../../../actions/fetchAllInterest";
import { fetchInterestPost } from "../../../actions/fetchInterestPost";
import { getInterestPosts } from "../../../actions/getInterestPosts";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";

const Actions = (props) => {
  const { t } = useTranslation();
  const [alertModal, setAlertModal] = useState(false);
  const [disableAlertModal, setDisableAlertModal] = useState(false);
  const [ignoreModal, setIgnoreModal] = useState(false);
  const [ignoreReason, setIgnoreReason] = useState("");
  // const [messageModal, setMessageModal] = useState(false);
  const [loadingIgnore, setLoadingIgnore] = useState(false);
  const [loading, setLoading] = useState(false);
  const { authProfile } = useSelector((state) => state.application);

  const handleIgnoreReason = (e) => {
    setIgnoreReason(e.target.value);
  };

  const handleAcceptMessageModal = () => {
    if (JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED") {
      setAlertModal(true);
    } else if (
      JSON.parse(localStorage.getItem("post_details")).status === "DISABLED"
    ) {
      setDisableAlertModal(true);
    } else {
      // setMessageModal(true);
      props.handleAcceptMessageModal();
    }
  };

  const handleIgnoreModal = () => {
    if (JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED") {
      setAlertModal(true);
    } else if (
      JSON.parse(localStorage.getItem("post_details")).status === "DISABLED"
    ) {
      setDisableAlertModal(true);
    } else {
      setIgnoreModal(true);
    }
  };

  // const handleShowInterest = (id, message, showPhone) => {
  //   if (localStorage.getItem("auth_token")) {
  //     setLoading(true);

  //     const data = `likedPostId=${id}&message=${message}&showPhone=${showPhone}`;

  //     axios
  //       .post(
  //         `${
  //           process.env.REACT_APP_API_URL
  //         }/v1/account/posts/${localStorage.getItem("postId")}/show_interest`,
  //         data,
  //         {
  //           headers: {
  //             "Content-Type": "application/x-www-form-urlencoded",
  //             Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         setMessageModal(false);
  //         setLoading(false);

  //         if (window.innerWidth > 575) {
  //           props.removePost();
  //           props.history.push(`/messages`);
  //           // setTimeout(() => props.fetchAllInterest(), 1000);
  //         } else {
  //           props.removePost();
  //           props.history.push(`/messages`);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setLoading(false);
  //       });
  //   }
  // };

  const handleIgnoreAllInt = () => {
    setLoadingIgnore(true);
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          props.post.id
        }?rejectReason=${ignoreReason}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        props.fetchInterestPost(props.post.id, "interests");
        props.fetchAllInterest();
        props.getInterestPosts();
        props.history.push(`/interests`);
        setLoadingIgnore(false);
        setIgnoreModal(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingIgnore(false);
      });
  };

  const freeRenew = () => {
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/free_renew`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        let url = "";
        if (
          localStorage.getItem("user_role") === "OPERATOR" ||
          localStorage.getItem("user_role") === "OPERATOR_L2"
        ) {
          url = `operator/managed_posts/${localStorage.getItem("postId")}`;
        } else {
          url = "account/posts";
        }
        axios
          .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          })
          .then((res) => {
            if (
              localStorage.getItem("user_role") === "OPERATOR" ||
              localStorage.getItem("user_role") === "OPERATOR_L2"
            ) {
              res.data[0] = res.data;
            }
            if (res.data.length > 0) {
              localStorage.setItem("post_details", JSON.stringify(res.data[0]));
            }

            props.fetchInterestPost(props.id, "interests");
            setAlertModal(false);
            setLoading(false);
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  const handleModalClose = () => {
    setAlertModal(false);
    setIgnoreModal(false);
    // setMessageModal(false);
    setDisableAlertModal(false);
  };

  const handleDeleteInterest = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem(
          "postId"
        )}/interests/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const operatorManaged =
  //   localStorage.getItem("user_role") !== "OPERATOR" &&
  //   localStorage.getItem("user_role") !== "OPERATOR_L2" &&
  //   localStorage.getItem("post_details") &&
  //   JSON.parse(localStorage.getItem("post_details")).isManaged;

  const userRole = localStorage.getItem("user_role");
  const operatorManaged =
    userRole !== "OPERATOR" &&
    userRole !== "OPERATOR_L2" &&
    authProfile?.isManaged;

  const interestAutoRejectionDate = !isEmpty(props.post?.likedAt)
    ? moment(props.post?.likedAt).add(10, "days").format("MMMM Do YYYY")
    : "";

  return (
    <React.Fragment>
      {/* {props.post.likeSeen !== undefined && props.post.ignored === false ? (
        <table className="respond-ways">
          <tbody>first
            <tr>
              <td>
                <i class="fas fa-globe"></i>
              </td>
              <td>
                <p
                  style={{
                    marginTop: 0,
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: "16px",
                    color: "#F54646",
                  }}
                >
                  {props.lng ? (
                    `You have received an online interest request from ${props.post.post.personalInfo.displayName}`
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-14">
                      ඔබගේ දැන්වීමට {props.post.post.personalInfo.displayName} කැමැත්ත
                      පෙන්වා ඇත.
                    </span>
                  )}
                </p>
                <Button
                  variant="contained"
                  className={
                    ((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged) ?
                    "" :
                    "int-accept-btn"
                  }
                  onClick={handleAcceptMessageModal}
                  disabled={((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged) ? true : false}
                  style={{
                    display: "inline-block",
                    marginRight: 10,
                  }}
                >
                  {props.lng ? (
                    "Accept"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-11">
                      පිලිගන්න
                    </span>
                  )}
                </Button>
                <Button
                  variant="contained"
                  className={
                    ((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged) ?
                    "" :
                    "received-ignore-btn"
                  }
                  onClick={handleIgnoreModal}
                  disabled={((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged) ? true : false}
                  style={{
                    display: "inline-block",
                  }}
                >
                  <span>
                    {props.lng ? (
                      "Ignore"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-11">
                        නොසලකා හරින්න
                      </span>
                    )}
                  </span>
                </Button>
              </td>
            </tr>
          </tbody>
          {(Config.TARGET_ENVIRONMENT === Config.TARGET_ENVIRONMENTS.DEVELOP) &&
            <button onClick={() => handleDeleteInterest(props.post.id)}>Remove Interest</button>
          }
        </table>
      ) : null} */}
      {props.post.likeSeen !== undefined && props.post.ignored === false ? (
        <>
          <Grid container wrap="nowrap" className="respond-ways">
            <Box className="padding-2">
              <i class="fas fa-globe"></i>
            </Box>
            <Grid item container>
              <Typography variant="body2" className="received-request-message">
                {t("message.recievedAnOnlineRequest", {
                  name: props.post.post?.personalInfo?.displayName,
                })}
              </Typography>
              <Box className="accept-before-rejection-msg-container">
                <WarningRoundedIcon style={{ color: "#ff9800" }} />
                <Typography
                  variant="body2"
                  className="accept-before-rejection-msg"
                >
                  {t("message.acceptToAvoidRejection", {
                    rejectionDate: interestAutoRejectionDate,
                  })}
                </Typography>
              </Box>
              <Grid item container className="column-gap-16">
                <Button
                  variant="contained"
                  className={!operatorManaged ? "int-accept-btn" : ""}
                  onClick={handleAcceptMessageModal}
                  disabled={operatorManaged}
                >
                  {t("common.accept")}
                </Button>
                <Button
                  variant="contained"
                  className={!operatorManaged ? "received-ignore-btn" : ""}
                  onClick={handleIgnoreModal}
                  disabled={operatorManaged}
                >
                  {t("common.ignore")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {Config.TARGET_ENVIRONMENT === Config.TARGET_ENVIRONMENTS.DEVELOP && (
            <button onClick={() => handleDeleteInterest(props.post.id)}>
              Remove Interest
            </button>
          )}{" "}
        </>
      ) : null}

      <AlertModal
        lng={props.lng}
        alertModal={alertModal}
        handleModalClose={handleModalClose}
        freeRenew={freeRenew}
        loading={loading}
      />

      <DisabledAlertModal
        lng={props.lng}
        disableAlertModal={disableAlertModal}
        handleModalClose={handleModalClose}
      />

      <IgnoreModal
        lng={props.lng}
        commingMsg={props.post.likeMessage}
        fname={props.post.post.personalInfo.fname}
        lnameMasked={props.post.post.personalInfo.lnameMasked}
        gender={props.post.post.personalInfo.gender}
        ignoreModal={ignoreModal}
        ignoreReason={ignoreReason}
        handleIgnoreReason={handleIgnoreReason}
        handleModalClose={handleModalClose}
        handleIgnoreAllInt={handleIgnoreAllInt}
        loadingIgnore={loadingIgnore}
        displayName={props.post.post.personalInfo.displayName}
      />

      {/* {messageModal ? (
        <AcceptIntMsgModal
          messageModal={messageModal}
          sendingPostId={props.post.post.id}
          commingMsg={props.post.likeMessage}
          fname={props.post.post.personalInfo.fname}
          lnameMasked={props.post.post.personalInfo.lnameMasked}
          gender={props.post.post.personalInfo.gender}
          loading={loading}
          handleModalClose={handleModalClose}
          handleShowInterest={handleShowInterest}
          lng={props.lng}
          displayName={props.post.post.personalInfo.displayName}
        />
      ) : null} */}
    </React.Fragment>
  );
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchIntPostData,
      fetchInterestPost,
      removePost,
      accordianSubTab,
      fetchAllInterest,
      getInterestPosts,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(Actions));
