import EnvVar from "../config/EnvVar";
import Constants from "../config/constant";
import FormMeta from "../meta/edit-self-profile.json";

const { APP_VARIANT } = Constants;

export const DISPLAY_NAME_FORMAT_OPTIONS = [
  {
    value: "FIRST",
    label: "First Name only",
    label_si: "මුල් නම පමණි",
    labelKey: "common.firstNameOnly",
    dependBy: [FormMeta.firstName.fieldName],
  },
  {
    value: "LAST",
    label: "Last Name only",
    label_si: "වාසගම පමණි",
    labelKey: "common.lastNameOnly",
    dependBy: [FormMeta.lastName.fieldName],
  },
  {
    value: "FIRST_LAST",
    label: "Full Name",
    labelKey: "common.fullName",
    label_si: "මුල් නම හා වාසගම",
    dependBy: [],
  },
  {
    value: "FIRST_LAST_CHAR1",
    label: "First Name and First letter of Last Name",
    label_si: "මුල් නම හා වාසගමේ මුල් අකුර",
    labelKey: "common.firstNameInitialLastName",
    dependBy: [FormMeta.firstName.fieldName],
  },
  {
    value: "FIRST_CHAR1_LAST",
    label: "First Letter of First Name and full Last Name",
    label_si: "මුල් නමේ මුල් අකුර හා වාසගම",
    labelKey: "common.firstInitialLastNameFull",
    dependBy: [FormMeta.lastName.fieldName],
  },
];

export const GENDER_OPTIONS = [
  {
    value: "MALE",
    label: "Male",
    label_si: "පුරුෂ",
  },
  {
    value: "FEMALE",
    label: "Female",
    label_si: "ස්ත්‍රී",
  },
];

export const FORM_OPTIONS = {
  enableOptions: [
    {
      value: "ENABLE",
      label: "Enable",
      label_si: "සක්‍රීය කරන්න",
    },
    {
      value: "DISABLE",
      label: "Disable",
      label_si: "අක්‍රීය කරන්න",
    },
  ],
  requiredOptions: [
    {
      value: "REQUIRED",
      label: "Required",
      label_si: "අත්‍යවශ්‍යයි",
    },
    {
      value: "NOT_REQUIRED",
      label: "Not required",
      label_si: "අත්‍යවශ්‍ය නොවේ",
    },
  ],
};

export const HOROSCOPE_MATCHING_OPTIONS = [
  {
    value: "true",
    label: "Required",
    label_si: "අත්‍යවශ්‍යයි",
  },
  {
    value: "false",
    label: "Not required",
    label_si: "අත්‍යවශ්‍ය නොවේ",
  },
];

export const HOROSCOPE_MATCHING_LABEL_OPTIONS = [
  {
    value: "true",
    label: "Essential",
    label_si: "අත්‍යවශ්‍යයි",
  },
  {
    value: "false",
    label: "Not essential",
    label_si: "අත්‍යවශ්‍ය නොවේ",
  },
];

export const ENABLE_BOOLEAN_OPTIONS = [
  {
    value: "true",
    label: "Enable",
    label_si: "සක්‍රීය කරන්න",
  },
  {
    value: "false",
    label: "Disable",
    label_si: "අක්‍රීය කරන්න",
  },
];

export const HAS_CHILDREN_OPTIONS = [
  {
    value: "NO",
    label: "No",
    label_si: "නැත",
  },
  {
    value: "YES",
    label: "Yes",
    label_si: "ඔව්",
  },
  {
    value: "UNDISCLOSED",
    label: "Prefer not to mention",
    label_si: "සඳහන් කිරීමට අකමැතියි",
  },
];

export const OFFLINE_RESPONSES_OPTIONS = [
  {
    value: "WHATSAPP",
    label: "Whatsapp",
    label_si: "Whatsapp හරහා",
    labelKey: "common.viaWhatsapp",
  },
  {
    value: "POST",
    label: "Post",
    label_si: "ලිපි හරහා",
    labelKey: "common.viaPost",
  },
  {
    value: "EMAIL",
    label: "Email",
    label_si: "Email හරහා",
    labelKey: "common.viaEmail",
  },
];

export const PARENT_OPTIONS = [
  { value: "FATHER", label: "Father", label_si: "පියා" },
  { value: "MOTHER", label: "Mother", label_si: "මව" },
];

export const DRINKING_PREFERENCES = [
  { value: "YES", label: "Yes", label_si: "ඔව්" },
  { value: "NO", label: "No", label_si: "නැත" },
  { value: "OCCASIONAL", label: "Occasionally", label_si: "ඇතැම් විට" },
];

export const SMOKING_PREFERENCES = [
  { value: "YES", label: "Yes", label_si: "ඔව්" },
  { value: "NO", label: "No", label_si: "නැත" },
];

export const DIFFERENTLY_ABLED_OPTIONS = [
  { id: "true", name: "Yes", name_si: "ඔව්" },
  { id: "false", name: "No", name_si: "නැහැ" },
];

export const NIC_VERIFIED_OPTIONS = [
  { id: "YES", name: "Yes", name_si: "ඔව්" },
  { id: "NO", name: "No", name_si: "නැහැ" },
];

export const HEIGHT_OPTIONS = [
  { name: "4ft (122cm)", value: 121.92 },
  { name: "4ft 1in (124cm)", value: 124.46 },
  { name: "4ft 2in (127cm)", value: 127 },
  { name: "4ft 3in (130cm)", value: 129.54 },
  { name: "4ft 4in (132cm)", value: 132.08 },
  { name: "4ft 5in (135cm)", value: 134.62 },
  { name: "4ft 6in (137cm)", value: 137.16 },
  { name: "4ft 7in (140cm)", value: 139.7 },
  { name: "4ft 8in (142cm)", value: 142.24 },
  { name: "4ft 9in (145cm)", value: 144.78 },
  { name: "4ft 10in (147cm)", value: 147.32 },
  { name: "4ft 11in (150cm)", value: 149.86 },
  { name: "5ft (152cm)", value: 152.4 },
  { name: "5ft 1in (155cm)", value: 154.94 },
  { name: "5ft 2in (157cm)", value: 157.48 },
  { name: "5ft 3in (160cm)", value: 160.02 },
  { name: "5ft 4in (163cm)", value: 162.56 },
  { name: "5ft 5in (165cm)", value: 165.1 },
  { name: "5ft 6in (168cm)", value: 167.64 },
  { name: "5ft 7in (170cm)", value: 170.18 },
  { name: "5ft 8in (173cm)", value: 172.72 },
  { name: "5ft 9in (175cm)", value: 175.26 },
  { name: "5ft 10in (178cm)", value: 177.8 },
  { name: "5ft 11in (180cm)", value: 180.34 },
  { name: "6ft (183cm)", value: 182.88 },
  { name: "6ft 1in (185cm)", value: 185.42 },
  { name: "6ft 2in (188cm)", value: 187.96 },
  { name: "6ft 3in (191cm)", value: 190.5 },
  { name: "6ft 4in (193cm)", value: 193.04 },
  { name: "6ft 5in (196cm)", value: 195.58 },
  { name: "6ft 6in (198cm)", value: 198.12 },
  { name: "6ft 7in (201cm)", value: 200.66 },
  { name: "6ft 8in (203cm)", value: 203.2 },
  { name: "6ft 9in (206cm)", value: 205.74 },
  { name: "6ft 10in (208cm)", value: 208.28 },
  { name: "6ft 11in (211cm)", value: 210.82 },
  { name: "7ft (213cm)", value: 213.36 },
  { name: "7ft 1in (216cm)", value: 215.9 },
  { name: "7ft 2in (218cm)", value: 218.44 },
  { name: "7ft 3in (221cm)", value: 220.98 },
  { name: "7ft 4in (224cm)", value: 223.52 },
  { name: "7ft 5in (226cm)", value: 226.06 },
  { name: "7ft 6in (229cm)", value: 228.6 },
];

const accountCreatedByLocal = [
  { value: "SELF", label: "Self", label_si: "තමන් විසින්" },
  {
    value: "FAMILY",
    label: "Parents/Family",
    label_si: "දෙමවුපියන්/පවුලේ අය",
  },
];

const accountCreatedByIndian = [
  { value: "SELF", label: "Self", label_si: "Self" },
  { value: "PARENT", label: "Parent", label_si: "Parent" },
  { value: "FAMILY", label: "Family", label_si: "Family" },
  { value: "FRIEND", label: "Friend", label_si: "Friend" },
];

export const ACCOUNT_CREATED_BY_OPTIONS =
  EnvVar.APP_VARIANT === APP_VARIANT.LOCAL
    ? accountCreatedByLocal
    : accountCreatedByIndian;

export const HAVE_DOSH_OPTIONS = [
  { value: "YES", label: "Yes", labelSI: "Yes" },
  { value: "NO", label: "No", labelSI: "No" },
  { value: "UNKNOWN", label: "Don't Know", labelSI: "Don't Know" },
];

export const ACCEPT_FROM_OTHER_COMMUNITY_OPTIONS = [
  { value: true, label: "Yes", labelSI: "Yes" },
  { value: false, label: "No", labelSI: "No" },
];

export const ADDITIONAL_COMMUNITY_OPTIONS = [
  { id: "OTHER", name: "Other", name_si: "Other" },
  { id: "UNDISCLOSED", name: "Rather not Say", name_si: "Rather not Say" },
];

export const RELATIONSHIP_OPTIONS = [
  { id: "SISTER", label: "Sister", label_si: "සහෝදරිය" },
  { id: "BROTHER", label: "Brother", label_si: "සහෝදරයා" },
];

export const RELATIONSHIP_TYPE_OPTIONS = [
  { id: "OLDER", label: "Older", label_si: "වැඩිමල්" },
  { id: "YOUNGER", label: "Younger", label_si: "බාල" },
  { id: "TWIN", label: "Twin", label_si: "නිවුන්" },
];

export const MARITAL_OPTIONS = [
  { id: "MARRIED", label: "Married", label_si: "විවාහක" },
  { id: "UNMARRIED", label: "Unmarried", label_si: "අවිවාහක" },
];

export const SIBLING_OPTIONS = [
  { id: "YOUNGER-BROTHER", label: "Young Brother", label_si: "බාල සහෝදරයා" },
  { id: "YOUNGER-SISTER", label: "Younger Sister", label_si: "බාල සහෝදරිය" },
  { id: "OLDER-BROTHER", label: "Older Brother", label_si: "වැඩිමල් සහෝදරයා" },
  { id: "OLDER-SISTER", label: "Older Sister", label_si: "වැඩිමල් සහෝදරිය" },
  { id: "TWIN-BROTHER", label: "Twin Brother", label_si: "නිවුන් සහෝදරයා" },
  { id: "TWIN-SISTER", label: "Twin Sister", label_si: "නිවුන් සහෝදරිය" },
];

export const SIBLING_CIVIL_STATUS_OPTIONS = [
  { id: "YES", label: "Married", label_si: "විවාහකයි" },
  { id: "NO", label: "Unmarried", label_si: "අවිවාහකයි" },
];

export const NOTIFICATION_OPTIONS = [
  { id: "off", label: "Off", label_si: "අක්‍රීය කරන්න" },
  { id: "sms", label: "SMS Only", label_si: "කෙටි පණිවුඩ පමණි" },
  { id: "email", label: "Email Only", label_si: "විද්‍යුත් තැපෑල පමණි" },
  { id: "email_sms", label: "Email and SMS", label_si: "විද්‍යුත් තැපෑල සහ කෙටි පණිවුඩ" },
];
