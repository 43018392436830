import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FormDropdown from "../../../components/molecules/form-dropdown";
import PreferenceToggle from "./preference-toggle";
import MailingSection from "./mailing-section";
import FormMeta from "./form-meta";
import OfflineReponsesDomain from "../../../features/offline-responses";
import { OFFLINE_RESPONSES_OPTIONS } from "../../../static-data";
import AppLogger from "../../../utils/logger";
import { setAppSnackNotification } from "../../../store/actions/application";
import PageSection from "../page-section";
import { getAuthUserAccount } from "../../../features/application";
import { validationSchema } from "./validation-schema";
import { OFFLINE_REQUEST_TYPES } from "../../../config/constant";

const OfflineResponses = () => {
  const { authAccount } = useSelector((state) => state.application);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const labelStyles = {
    textAlign: "left",
    width: "60%",
    fontSize: 20,
  };

  const initialValues = useMemo(() => {
    return OfflineReponsesDomain.bindInitialFormValues({ authAccount });
  }, [authAccount]);

  const handleSubmit = async ({ values, initialValues }) => {
    const hasChangedAddressFields = OfflineReponsesDomain.hasChangedAddress({
      initialValues,
      values,
    });
    const hasChangedPreferences = OfflineReponsesDomain.hasChangedPreferences({
      initialValues,
      values,
    });

    const shouldUpdateAddress =
      (values.showOfflineId &&
        values.offlineRequestType === OFFLINE_REQUEST_TYPES.POST) ||
      values.subscribeMagazine;

    try {
      if (hasChangedAddressFields && shouldUpdateAddress) {
        const addressFields = OfflineReponsesDomain.getUpdatedAddressValues({
          values,
        });
        const response = await OfflineReponsesDomain.updateAddress({
          addressFields,
        });

        if (response.success) {
          dispatch(
            setAppSnackNotification({
              severity: "success",
              message: t("common.updatedsuccessfully"),
            })
          );
        }
      }

      if (hasChangedPreferences) {
        const preferences = OfflineReponsesDomain.getUpdatedPreferences({
          values,
        });
        const response = await OfflineReponsesDomain.updatePreferences({
          preferences,
        });
        if (response.success) {
          dispatch(
            setAppSnackNotification({
              severity: "success",
              message: t("common.updatedsuccessfully"),
            })
          );
        }
      }

      if (
        (hasChangedAddressFields && shouldUpdateAddress) ||
        hasChangedPreferences
      ) {
        dispatch(getAuthUserAccount());
      }
    } catch (error) {
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: t("errors.errorOccured"),
        })
      );
      AppLogger.exception(error);
      console.log("Error >>>", error);
    }
  };

  return (
    <PageSection
      id="offline-responses-div"
      heading={t("createflow.offlineResponses")}
      subTitle={t("createflow.offlineResponsesSubheading")}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema({ t })}
        enableReinitialize
        onSubmit={(values, actions) =>
          handleSubmit({ values, actions, initialValues })
        }
      >
        {({ values, handleChange, dirty, resetForm, handleSubmit }) => (
          <Grid container direction="column" className="row-gap-16">
            <PreferenceToggle
              id={FormMeta.showOfflineId.fieldName}
              label={t(FormMeta.showOfflineId.label)}
              value={values[FormMeta.showOfflineId.fieldName]}
              handleChange={handleChange}
            />

            <Grid container xs={12} sm={6}>
              <FormDropdown
                fieldName={FormMeta.offlineRequestType.fieldName}
                label={t(FormMeta.offlineRequestType.label)}
                labelStyles={labelStyles}
                options={OFFLINE_RESPONSES_OPTIONS}
                keyExtractor={FormMeta.offlineRequestType.keyExtractor}
                labelExtractor={FormMeta.offlineRequestType.labelExtractor}
                lang={i18n.language}
                value={values[FormMeta.offlineRequestType.fieldName]}
                onChange={handleChange}
              />
            </Grid>

            <PreferenceToggle
              id={FormMeta.subscribeMagazine.fieldName}
              label={t(FormMeta.subscribeMagazine.label)}
              value={values[FormMeta.subscribeMagazine.fieldName]}
              handleChange={handleChange}
            />

            <MailingSection />

            <Grid
              container
              className="column-gap-8"
              justifyContent="flex-end"
              wrap="nowrap"
            >
              <Button
                variant="outlined"
                className="setting-btn-secondary"
                disabled={!dirty}
                onClick={() => resetForm()}
              >
                {t("common.cancel")}
              </Button>
              <Button
                variant="contained"
                className="setting-btn-primary"
                disabled={!dirty}
                onClick={handleSubmit}
              >
                {t("common.save")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
    </PageSection>
  );
};

export default OfflineResponses;
