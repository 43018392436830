import React, { useMemo, useRef, useState } from "react";
import { Grid, Typography, OutlinedInput } from "@material-ui/core";
import "./style.css";
import FormInteractiveButton from "../form-interactive-button";
import FormItemLabel from "../../atoms/form-item-label";

export const LABEL_POSITION = Object.freeze({
  top: "top",
  left: "left",
});

export const Mode = Object.freeze({
  standard: "standard",
  interactive: "interactive",
});

const FormTextInput = ({
  mode = Mode.standard,
  showInteractiveActions = false,
  onClickInteractiveSave,
  onClickInteractiveClear,
  fieldName,
  value,
  onChange,
  label,
  labelPosition = LABEL_POSITION.left,
  lang,
  multiline = false,
  maxCharactorCount = "",
  placeholder = undefined,
  labelStyles = {},
  visibility = true,
  disabled = false,
  errorText = "",
  onBlur = undefined,
  max = undefined,
  showLabel = true,
  hideCharactorLength = false,
  inputType = "text",
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputElRef = useRef(null);

  const charLengthVisibility = useMemo(() => {
    return isFocused && max && typeof max === "number";
  }, [max, isFocused]);

  const inputElementRect = inputElRef.current?.getBoundingClientRect() ?? {};

  return (
    visibility && (
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          className="form-text-input"
          alignItems="center"
        >
          {showLabel && (
            <FormItemLabel
              text={label}
              lang={lang}
              labelPosition={labelPosition}
              styleOverrides={labelStyles}
            />
          )}
          <Grid item xs>
            <OutlinedInput
              ref={inputElRef}
              value={value}
              name={fieldName}
              onChange={onChange}
              notched={false}
              multiline={multiline ? true : false}
              placeholder={placeholder}
              fullWidth
              className={`input-text input-text-${lang}`}
              disabled={disabled}
              type={inputType}
              error={Boolean(errorText)}
              inputProps={{
                maxLength: maxCharactorCount,
              }}
              onBlur={(event) => {
                event.stopPropagation();
                setIsFocused(false);
                if (typeof onBlur === "function") {
                  onBlur(event);
                }
              }}
              onFocus={(event) => {
                event.preventDefault();
                setIsFocused(true);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          style={{
            width: inputElementRect.width ?? "100%",
            position: "relative",
            left: `calc(100% - ${inputElementRect.width ?? 0}px)`,
          }}
        >
          <Typography
            variant="caption"
            className={`form-error-text form-error-text-${lang}`}
          >
            {errorText ?? ""}
          </Typography>
          {(charLengthVisibility || Boolean(errorText)) &&
            !hideCharactorLength && (
              <Typography
                variant="caption"
                className={
                  errorText || value?.length > max ? "form-error-text" : ""
                }
              >{`${value?.length ?? 0} /${max}`}</Typography>
            )}
        </Grid>
        {mode === Mode.interactive && showInteractiveActions && (
          <FormInteractiveButton
            onClickInteractiveClear={onClickInteractiveClear}
            onClickInteractiveSave={onClickInteractiveSave}
          />
        )}
      </Grid>
    )
  );
};

export default FormTextInput;
