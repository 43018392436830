import { Grid, Typography } from "@material-ui/core";
import React from "react";
import "../style.css";
import { Skeleton } from "@material-ui/lab";

const PageSection = ({
  id = "",
  heading,
  subTitle,
  containerStyles,
  children,
  initialized = true,
}) => {
  const skeletonProps = {
    height: 40,
    width: "100%",
  };

  return (
    <Grid
      id={id}
      item
      container
      direction="column"
      className="page-content page-section"
      style={containerStyles}
    >
      <Typography className="font-weight-600">{heading}</Typography>
      <Typography variant="caption" className="font-weight-300">
        {subTitle}
      </Typography>
      {initialized ? (
        <Grid container direction="column" className="margin-top-16">
          {children}
        </Grid>
      ) : (
        <Grid container xs={12} md={6}>
          <Skeleton {...skeletonProps} />
          <Skeleton {...skeletonProps} />
          <Skeleton {...skeletonProps} />
          <Skeleton {...skeletonProps} />
        </Grid>
      )}
    </Grid>
  );
};

export default PageSection;
