import React from "react";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import withLanguageMigrator from "../../hoc/language-migrator";

const AppFooter = ({ lng }) => {
  return (
    <>
      <div className="create-floor-footer-desktop">
        <Footer lng={lng} />
      </div>
      <div className="create-floor-footer-mobile">
        <FooterMobile lng={lng} />
      </div>
    </>
  );
};

export default withLanguageMigrator(AppFooter);
