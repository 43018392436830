import httpAdapter, {
  ApiVersion,
  ContentType,
  HttpMethod,
  ResponseType,
} from "../../../utils/http-adapter";

const PostServiceApi = {
  searchPosts: async ({ isAuthProfileUser, filters }) =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "posts/search",
      authneticated: isAuthProfileUser,
      queryParams: filters,
    }),
  getPostImages: async ({ postId, imageId, thumbnail }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/images/${imageId}?thumbnail=${thumbnail}`,
      authneticated: true,
      responseType: ResponseType.BLOB,
    });
  },
  getMatchById: async ({ postId, matchId }) =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V2,
      path: `posts/${postId}/interests/${matchId}`,
      authneticated: true,
    }),
  getInterestById: async ({ postId, interestId }) =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V2,
      path: `posts/${postId}/interests/${interestId}`,
      authneticated: true,
    }),
  getMatchImages: async ({ postId, matchId, imageId, thumbnail }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/matches/${matchId}/images/${imageId}/?thumbnail=${thumbnail}`,
      authneticated: true,
      responseType: ResponseType.BLOB,
    });
  },
  getPostHoroscopeImage: async ({ postId, imageId }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/horoscope_images/${imageId}`,
      authneticated: true,
      responseType: ResponseType.BLOB,
    });
  },
  getMatchHoroscopeImage: async ({ postId, interestId, img }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/matches/${interestId}/images/${img}?thumbnail=false`,
      authneticated: true,
      responseType: ResponseType.BLOB,
    });
  },
  getPostById: async ({ id, authneticated, queryParams }) =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `posts/${id}`,
      authneticated,
      queryParams,
    }),
  getOperatorManagedPost: async ({ id }) =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `operator/managed_posts/${id}`,
      authneticated: true,
    }),
  postUserMakertingResource: async ({ postId, sourceId }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/marketing_source`,
      body: { sourceId },
      contentType: ContentType.FORM_URL_ENCODED,
      authneticated: true,
    });
  },
  postConfirmBankTransaction: async ({ postId }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/confirm_bank_tx`,
      body: {},
      authneticated: true,
    });
  },
  getPushNotificationAllowedTypes: async () => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `account/pushnotification_allowed_types`,
      authneticated: true,
    });
  },
  updatePushNotificationOption: async ({ typeId, optionId }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: `account/pushnotification_option`,
      contentType: ContentType.FORM_URL_ENCODED,
      body: { typeId, optionId },
      authneticated: true,
    });
  },
  updateAdVisibility: async ({ postId, data }) => {
    return await httpAdapter.request({
      method: HttpMethod.PUT,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}`,
      contentType: ContentType.JSON,
      body: data,
      authneticated: true,
    });
  },
};

export default PostServiceApi;
