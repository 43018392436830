import FormMeta from "../../pages/settings-page/offline-responses/form-meta";
import { isEqual } from "lodash";
import UserApi from "../../services/api/user";

const bindInitialFormValues = ({ authAccount }) => {
  const fieldValues = {};

  fieldValues[FormMeta.showOfflineId.fieldName] =
    authAccount.userPreferences?.showOfflineId ?? false;
  fieldValues[FormMeta.offlineRequestType.fieldName] =
    authAccount.userPreferences?.offlineRequestType ?? "";
  fieldValues[FormMeta.subscribeMagazine.fieldName] =
    authAccount.userPreferences?.subscribeMagazine ?? false;
  fieldValues[FormMeta.nameOfRecipient.fieldName] =
    authAccount.address?.name ?? "";
  fieldValues[FormMeta.addressLineOne.fieldName] =
    authAccount.address?.line1 ?? "";
  fieldValues[FormMeta.addressLineTwo.fieldName] = authAccount.address?.line2;
  fieldValues[FormMeta.city.fieldName] = authAccount.address?.city ?? "";
  fieldValues[FormMeta.country.fieldName] =
    authAccount.address?.country?.name ?? "";

  return fieldValues;
};

const hasChangedAddress = ({ initialValues, values }) => {
  const addressFields = [
    FormMeta.nameOfRecipient.fieldName,
    FormMeta.addressLineOne.fieldName,
    FormMeta.addressLineTwo.fieldName,
    FormMeta.city.fieldName,
    FormMeta.country.fieldName,
  ];

  const initialAddressValues = Object.fromEntries(
    Object.entries(initialValues).filter(([key, value]) =>
      addressFields.includes(key)
    )
  );

  const currentAddressValues = Object.fromEntries(
    Object.entries(values).filter(([key, value]) => addressFields.includes(key))
  );

  return !isEqual(initialAddressValues, currentAddressValues);
};

const hasChangedPreferences = ({ initialValues, values }) => {
  const preferencesFields = [
    FormMeta.showOfflineId.fieldName,
    FormMeta.subscribeMagazine.fieldName,
    FormMeta.offlineRequestType.fieldName,
  ];

  const initialFieldValues = Object.fromEntries(
    Object.entries(initialValues).filter(([key, value]) =>
      preferencesFields.includes(key)
    )
  );

  const currentFieldValues = Object.fromEntries(
    Object.entries(values).filter(([key, value]) =>
      preferencesFields.includes(key)
    )
  );

  return !isEqual(initialFieldValues, currentFieldValues);
};

const getUpdatedAddressValues = ({ values }) => {
  return {
    [FormMeta.nameOfRecipient.jsonKey]:
      values[FormMeta.nameOfRecipient.fieldName],
    [FormMeta.addressLineOne.jsonKey]:
      values[FormMeta.addressLineOne.fieldName],
    [FormMeta.addressLineTwo.jsonKey]:
      values[FormMeta.addressLineTwo.fieldName],
    [FormMeta.city.jsonKey]: values[FormMeta.city.fieldName],
    // [FormMeta.country.jsonKey]: values[FormMeta.country.fieldName],
    [FormMeta.country.jsonKey]: "LK",
  };
};

const getUpdatedPreferences = ({ values }) => {
  return {
    [FormMeta.showOfflineId.fieldName]:
      values[FormMeta.showOfflineId.fieldName],
    [FormMeta.subscribeMagazine.fieldName]:
      values[FormMeta.subscribeMagazine.fieldName],
    [FormMeta.offlineRequestType.fieldName]:
      values[FormMeta.offlineRequestType.fieldName],
  };
};

const updateAddress = async ({ addressFields }) => {
  try {
    const response = await UserApi.postUserAddress({ addressFields });
    if (!response.success) {
      throw new Error("Unable to update Address");
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updatePreferences = async ({ preferences }) => {
  try {
    const response = await UserApi.postUserPreferences({ preferences });
    if (!response.success) {
      throw new Error("Unable to update Preferences");
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const OfflineReponsesDomain = {
  bindInitialFormValues,
  hasChangedAddress,
  hasChangedPreferences,
  getUpdatedAddressValues,
  getUpdatedPreferences,
  updateAddress,
  updatePreferences,
};

export default OfflineReponsesDomain;
