import { Grid, Typography } from "@material-ui/core";
import React from "react";
import Notice, { Varaint } from "../../../../components/molecules/notice";
import { useTranslation } from "react-i18next";
import EnvelopImage from "../../../../../images/address-envelop.png";
import FormTextInput from "../../../../components/molecules/form-text-input";
import FormMeta from "../form-meta";
import { useFormikContext } from "formik";
import { OFFLINE_REQUEST_TYPES } from "../../../../config/constant";

const MailingSection = () => {
  const { t, i18n } = useTranslation();
  const { values, handleChange, errors, submitCount } = useFormikContext();

  const showErrorText = submitCount > 0;

  const visibility =
    (values[FormMeta.showOfflineId.fieldName] &&
      values[FormMeta.offlineRequestType.fieldName] ===
        OFFLINE_REQUEST_TYPES.POST) ||
    values[FormMeta.subscribeMagazine.fieldName];

  return (
    visibility && (
      <Grid container className="row-gap-16">
        <Notice
          varaint={Varaint.primary}
          label={t("settingPage.magazineNotice")}
          lang={i18n.language}
        />
        <Grid container direction="row">
          <Grid
            item
            container
            xs={12}
            sm={6}
            className="row-gap-8"
            style={{ backgroundColor: "" }}
          >
            <FormTextInput
              fieldName={FormMeta.nameOfRecipient.fieldName}
              label={t(FormMeta.nameOfRecipient.label)}
              value={values[FormMeta.nameOfRecipient.fieldName]}
              onChange={handleChange}
              errorText={
                showErrorText && errors[FormMeta.nameOfRecipient.fieldName]
              }
              hideCharactorLength={true}
            />
            <FormTextInput
              fieldName={FormMeta.addressLineOne.fieldName}
              label={t(FormMeta.addressLineOne.label)}
              value={values[FormMeta.addressLineOne.fieldName]}
              onChange={handleChange}
              errorText={
                showErrorText && errors[FormMeta.addressLineOne.fieldName]
              }
              hideCharactorLength={true}
            />
            <FormTextInput
              fieldName={FormMeta.addressLineTwo.fieldName}
              label={`${t(FormMeta.addressLineTwo.label)} (${t(
                "common.optional"
              )})`}
              value={values[FormMeta.addressLineTwo.fieldName]}
              onChange={handleChange}
              errorText={
                showErrorText && errors[FormMeta.addressLineTwo.fieldName]
              }
              hideCharactorLength={true}
            />
            <FormTextInput
              fieldName={FormMeta.city.fieldName}
              label={t(FormMeta.city.label)}
              value={values[FormMeta.city.fieldName]}
              onChange={handleChange}
              errorText={showErrorText && errors[FormMeta.city.fieldName]}
              hideCharactorLength={true}
            />
            <FormTextInput
              fieldName={FormMeta.country.fieldName}
              label={t(FormMeta.country.label)}
              value={values[FormMeta.country.fieldName]}
              onChange={handleChange}
              errorText={showErrorText && errors[FormMeta.country.fieldName]}
              hideCharactorLength={true}
              disabled={true}
            />
          </Grid>
          <Grid item container xs={12} sm={6}>
            <Grid className="envelope-container">
              <Grid container className="p-16">
                <img src={EnvelopImage} alt="envelope" width="100%" />
              </Grid>
              <Grid
                container
                direction="column"
                className="envelope-address-wrapper"
              >
                <Typography variant="caption">
                  {values.nameOfRecipient}
                </Typography>
                <Typography variant="caption">
                  {values.addressLineOne}
                </Typography>
                <Typography variant="caption">
                  {values.addressLineTwo}
                </Typography>
                <Typography variant="caption">{values.city}</Typography>
                <Typography variant="caption">{values.country}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

export default MailingSection;
