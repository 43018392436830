import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import moment from "moment";

const ManageSubscription = ({ post, language, history }) => {
  const PageView = () => {
    if (post === undefined) {
      return null;
    }

    const { status: profileStatus, expiresAt, personalInfo } = post;
    const { residentCountry } = personalInfo;

    if (profileStatus === "INCOMPLETE") {
      return (
        <div
          className="profile-div"
          style={{ marginTop: "10px", width: "60%" }}
        >
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            <Typography variant="h6" className="">
              <span style={{ color: "#333333", fontSize: "16px" }}>
                {language ? "Complete my Ad" : "මගේ දැන්වීම සම්පූර්ණ කරන්න"}
              </span>
            </Typography>
            <p
              className=""
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                color: "#333333",
                fontSize: "13px",
                fontWeight: "lighter",
                letterSpacing: "0.0075em",
              }}
            >
              {language
                ? "Complete your ad and start connecting"
                : "ඔබේ දැන්වීම සම්පූර්ණ කර සම්බන්ධ කිරීම ආරම්භ කරන්න"}
            </p>
          </div>
          <Button
            className="action-btn"
            variant="contained"
            onClick={() => {
              history.push("/complete-ad-personal");
            }}
            style={{ width: 200 }}
          >
            {language ? (
              "Complete Ad"
            ) : (
              <span className="sinhala-w-600 sinhala-size-10">
                දැන්වීම සම්පූර්ණ කරන්න
              </span>
            )}
          </Button>
        </div>
      );
    }

    if (profileStatus === "PAYMENT_PENDING") {
      return (
        <div
          className="profile-div"
          style={{ marginTop: "10px", width: "60%" }}
        >
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            <Typography variant="h6" className="">
              <span style={{ color: "#333333", fontSize: "16px" }}>
                {language ? "Activate my ad" : "මගේ දැන්වීම සක්‍රිය කරන්න"}
              </span>
            </Typography>
            <p
              className=""
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                color: "#333333",
                fontSize: "13px",
                fontWeight: "lighter",
                letterSpacing: "0.0075em",
              }}
            >
              {language
                ? "Select the most convenient payment method for you"
                : "ඔබ සඳහා වඩාත් පහසු ගෙවීම් ක්‍රමය තෝරන්න"}
            </p>
          </div>
          <Button
            className="action-btn"
            variant="contained"
            style={{ width: 200 }}
            onClick={(e) => {
              e.preventDefault();
              history.push("/pay-for-ad");
            }}
          >
            {language ? (
              "Pay Now"
            ) : (
              <span className="sinhala-w-600 sinhala-size-10">ගෙවන්න</span>
            )}
          </Button>
        </div>
      );
    }

    if (profileStatus === "LIVE" || profileStatus === "EXPIRED") {
      const today = moment().local();
      const moment01 = moment(expiresAt).subtract(7, "days").local();
      const moment02 = moment(expiresAt).add(7, "days").local();
      const isValidToPromotion = today.isBetween(moment01, moment02);
      if (isValidToPromotion) {
        return (
          <div
            className="profile-div"
            style={{ marginTop: "10px", width: "60%" }}
          >
            <div
              style={{
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              <Typography variant="h6" className="">
                <span style={{ color: "#333333", fontSize: "16px" }}>
                  {language ? "Renew my ad" : "මගේ දැන්වීම අලුත් කරන්න"}
                </span>
              </Typography>

              <p
                className=""
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  color: "#333333",
                  fontSize: "13px",
                  fontWeight: "lighter",
                  letterSpacing: "0.0075em",
                }}
              >
                {language
                  ? `20% discount if you renew before ${moment
                      .utc(moment.utc(expiresAt).add(7, "days"))
                      .local()
                      .format("Do MMM")}`
                  : `${moment
                      .utc(moment.utc(expiresAt).add(7, "days"))
                      .local()
                      .format("Do MMM")} ට පෙර අලුත් කළහොත් 20% වට්ටමක්`}
              </p>
            </div>
            <Button
              className="action-btn"
              variant="contained"
              onClick={() => history.push("/pay-for-ad")}
              style={{ width: 150 }}
            >
              {language ? (
                "Renew My Ad"
              ) : (
                <span className="sinhala-w-600 sinhala-size-10">
                  මගේ දැන්වීම අලුත් කරන්න
                </span>
              )}
            </Button>
          </div>
        );
      }
    }

    if (profileStatus === "EXPIRED") {
      return (
        <div
          className="profile-div"
          style={{ marginTop: "10px", width: "60%" }}
        >
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            <Typography variant="h6" className="">
              <span style={{ color: "#333333", fontSize: "16px" }}>
                {language ? "Renew my ad" : "මගේ දැන්වීම අලුත් කරන්න"}
              </span>
            </Typography>
          </div>
          <Button
            className="action-btn"
            variant="contained"
            onClick={() => history.push("/pay-for-ad")}
            style={{ width: 150, marginTop: 25 }}
          >
            {language ? (
              "Renew My Ad"
            ) : (
              <span className="sinhala-w-600 sinhala-size-10">
                මගේ දැන්වීම අලුත් කරන්න
              </span>
            )}
          </Button>
        </div>
      );
    }

    if (post.isFeatured) {
      const featuredEndDate = moment(post.featureUntil).format("YYYY-MM-DD");
      return (
        <div
          className="profile-div"
          style={{ marginTop: "10px", width: "60%" }}
        >
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            <Typography
              className=""
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                color: "#333333",
                fontSize: "13px",
                fontWeight: "lighter",
                letterSpacing: "0.0075em",
              }}
            >
              {language
                ? `Your Ad has been converted to a Top Ad until ${featuredEndDate}.`
                : `ඔබගේ දැන්වීම ${featuredEndDate} දක්වා  Top Ad ලෙස පරිවර්තනය කර ඇත.`}
            </Typography>
          </div>
        </div>
      );
    }

    return (
      <div className="profile-div" style={{ marginTop: "10px", width: "60%" }}>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          <Typography variant="h6" className="">
            <span style={{ color: "#333333", fontSize: "16px" }}>
              {language
                ? "Feature my ad for 7 days"
                : "දින 7ක් සඳහා මගේ දැන්වීම විශේෂාංගගත කරන්න"}
            </span>
          </Typography>
          <p
            className=""
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              color: "#333333",
              fontSize: "13px",
              fontWeight: "lighter",
              letterSpacing: "0.0075em",
            }}
          >
            {language
              ? "By making your ad a Top Ad you can get featured at the top of the search results and get additional views for 7 days."
              : "ඔබේ දැන්වීම විශේෂාංගගත කිරීමෙන් එය දින 7ක් දක්වා සෙවුම් ප්‍රතිඵලවල ඉහළින්ම පෙන්වනු ඇති අතර එ මගින් අමතර ආකර්ෂණයක් ලබා ගත හැක."}
          </p>
          <p
            className=""
            style={{ marginTop: "12px", marginBottom: "0px", color: "#333333" }}
          >
            {residentCountry.code === "LK" ? "Rs.1000.00" : "USD 6.00"}
          </p>
          {post.excludeFromSearch && (
            <p
              style={{
                fontSize: "smaller",
                color: "#927502",
              }}
            >
              {language
                ? "* Your Ad is currently hidden from public search. Your Ad will be automatically visible to the public after making the top Ad payment."
                : "* ඔබගේ දැන්වීම දැනට පොදු සෙවුමෙන් සඟවා ඇත. Top Ad සදහා මුදල්  ගෙවීමෙන් පසු ඔබේ දැන්වීම ස්වයංක්‍රීයව පොදු සෙවුමේ පෙන්වයි."}
            </p>
          )}
        </div>
        <Button
          className="common-action-btn"
          variant="contained"
          onClick={() => history.push("/payments/top-ad")}
          style={{ marginTop: "15px" }}
        >
          {language ? (
            "Make Top Ad"
          ) : (
            <span className="sinhala-w-600 sinhala-size-10">
              Top Ad දැන්වීම සාදන්න
            </span>
          )}
        </Button>
      </div>
    );
  };

  return (
    <Grid container direction="column">
      <p className="sec-heading" style={{ marginBottom: 5 }}>
        {language ? (
          "Manage Subscription"
        ) : (
          <span className="sinhala-w-600 sinhala-size-14">
            වලංගු කාලය පිලිබඳ සැකසුම්
          </span>
        )}
      </p>
      {<PageView />}
    </Grid>
  );
};

ManageSubscription.propTypes = {
  language: PropTypes.string,
  history: PropTypes.object,
  post: PropTypes.object,
};

export default ManageSubscription;
