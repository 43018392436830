import { Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "../../style.css";
import React from "react";

const FeaturesView = () => {
  const features = [
    "Dedicated relationship manager",
    "Family meeting arrangement",
    "6 months ad duration",
    "Monthly printed magazine",
    "Always on boost ad",
    "Along with all the features",
  ];

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="premium-features-section"
    >
      <Grid item container className="premium-feature-list">
        {features.map((element) => (
          <Grid
            item
            container
            xs={12}
            md={4}
            direction="row"
            wrap="nowrap"
            className="premium-feature-item"
          >
            <CheckCircleIcon className="premium-feature-icon" />
            <Typography variant="body1" className="premium-feature-txt primary-font">
              {element}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Typography variant="h5" className="pricing-title primary-font">
        LKR 25,000/-
      </Typography>
    </Grid>
  );
};

export default FeaturesView;
