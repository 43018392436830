import {
  setSortedCountries,
  setGenericAllCollection,
} from "../../store/actions/generic-data";
import {
  DISPLAY_NAME_FORMAT_OPTIONS,
  GENDER_OPTIONS,
  FORM_OPTIONS,
  HEIGHT_OPTIONS,
  HAS_CHILDREN_OPTIONS,
  DRINKING_PREFERENCES,
  SMOKING_PREFERENCES,
  HAVE_DOSH_OPTIONS,
  DIFFERENTLY_ABLED_OPTIONS,
  ACCOUNT_CREATED_BY_OPTIONS,
  NIC_VERIFIED_OPTIONS,
} from "../../static-data";
import GenericDataAPI from "../../services/api/generic";
import { sortBy, isFunction } from "lodash";
import { APP_LANG } from "../../utils/locale-handler";
import { HttpCode, OTHER_PROFESSION_ID } from "../../config/constant";
import { setAppSnackNotification } from "../../store/actions/application";
import Config from "../../config";
import AppLogger from "../../utils/logger";

const GENERIC_DATA_LOCALE_ORDER = {
  countries: { [APP_LANG.EN]: "name", [APP_LANG.SI]: "name_si" },
};

const GENERIC_SORT_KEYS = ["sortOrder"];

export const initializedGenericOptions = (appLanguage) => async (dispatch) => {
  try {
    const genericDataStates = {
      nameFormatOptions: DISPLAY_NAME_FORMAT_OPTIONS,
      genderOptions: GENDER_OPTIONS,
      formOptions: FORM_OPTIONS,
      heightOptions: HEIGHT_OPTIONS,
      hasChildrenOptions: HAS_CHILDREN_OPTIONS,
      drinkingPreferences: DRINKING_PREFERENCES,
      smokingPrefernces: SMOKING_PREFERENCES,
      haveDoshOptions: HAVE_DOSH_OPTIONS,
      accountOwnership: ACCOUNT_CREATED_BY_OPTIONS,
      differentlyAbledOptions: DIFFERENTLY_ABLED_OPTIONS,
      nicVerifiedOptions: NIC_VERIFIED_OPTIONS,
    };

    let apiResponse = await GenericDataAPI.getGenericFilters();
    if (!apiResponse.success) {
      throw new Error("Failed GenericDataAPI.getGenericFilters()");
    }
    if (Array.isArray(apiResponse.body?.countries)) {
      genericDataStates.countries = sortCountriesByLanguage(
        appLanguage,
        apiResponse.body.countries
      );
    }
    if (Array.isArray(apiResponse.body?.allCountries)) {
      genericDataStates.secondaryCountries = apiResponse.body.allCountries;
    }
    if (Array.isArray(apiResponse.body?.civilStatuses)) {
      genericDataStates.civilStatusOptions = sortBy(
        apiResponse.body.civilStatuses,
        GENERIC_SORT_KEYS
      );
    }
    if (Array.isArray(apiResponse.body?.ethnicities)) {
      genericDataStates.ethnicities = sortBy(
        apiResponse.body.ethnicities,
        GENERIC_SORT_KEYS
      );
    }
    if (Array.isArray(apiResponse.body?.religions)) {
      const religions = apiResponse.body.religions;
      let sortedReligions = religions;
      switch (Config.CURRENT_PLATFORM) {
        case Config.PLATFORMS.LK:
          sortedReligions = sortBy(religions, GENERIC_SORT_KEYS);
          break;
        case Config.PLATFORMS.IN:
          const otherOption = religions.find(
            (current) => current.id === "other"
          );
          sortedReligions = religions.filter(
            (current) => current.id !== "other"
          );
          if (otherOption !== null) {
            sortedReligions.push(otherOption);
          }
          break;
        default:
          sortedReligions = religions;
          break;
      }
      genericDataStates.religions = sortedReligions;
    }
    if (Array.isArray(apiResponse.body?.educationLevels)) {
      genericDataStates.educationLevels = sortBy(
        apiResponse.body.educationLevels,
        GENERIC_SORT_KEYS
      );
    }
    if (Array.isArray(apiResponse.body?.foodPreferences)) {
      genericDataStates.foodPreferences = sortBy(
        apiResponse.body.foodPreferences,
        GENDER_OPTIONS
      );
    }
    if (Array.isArray(apiResponse.body?.professions)) {
      genericDataStates.professions = sortGenericOptionsByLanguage(
        apiResponse.body.professions,
        appLanguage,
        [OTHER_PROFESSION_ID]
      );
    }
    if (Array.isArray(apiResponse.body?.visaStatuses)) {
      genericDataStates.visaStatusOptions = sortBy(
        apiResponse.body.visaStatuses,
        GENERIC_SORT_KEYS
      );
    }
    if (Array.isArray(apiResponse.body?.gothras)) {
      genericDataStates.gothraOptions = apiResponse.body.gothras;
    }
    if (Array.isArray(apiResponse.body?.motherTongues)) {
      genericDataStates.motherTongues = sortBy(
        apiResponse.body.motherTongues,
        "name"
      );
    }
    if (Array.isArray(apiResponse.body?.stars)) {
      genericDataStates.starOptions = apiResponse.body.stars;
    }
    if (Array.isArray(apiResponse.body?.starSigns)) {
      genericDataStates.starSignOptions = apiResponse.body.starSigns;
    }
    genericDataStates.initialized = true;
    dispatch(setGenericAllCollection(genericDataStates));
  } catch (error) {
    AppLogger.exception(error);
    dispatch(
      setAppSnackNotification({
        severity: "error",
        message: "Unable to fetch generic data",
      })
    );
  }
};

export const sortCountryOptionsByLanguage =
  (appLanguage, payload = {}) =>
  (dispatch) => {
    const sortedCountries = sortCountriesByLanguage(
      appLanguage,
      payload?.countries ?? {}
    );
    dispatch(setSortedCountries(sortedCountries));
  };

const sortCountriesByLanguage = (appLanguage, countries = []) => {
  const { OTHER_COUNTRY_CODE } = Config;
  const sortedCountries = sortBy(
    countries.filter((current) => current.code !== OTHER_COUNTRY_CODE),
    GENERIC_DATA_LOCALE_ORDER.countries[appLanguage]
  );
  const otherCountryOption = countries.find(
    (current) => current.code === OTHER_COUNTRY_CODE
  );
  if (otherCountryOption !== undefined) {
    sortedCountries.push(otherCountryOption);
  }
  return sortedCountries;
};

const sortGenericOptionsByLanguage = (options, appLanguage, excludes = []) => {
  let sortedOptions = sortBy(options, [
    GENERIC_DATA_LOCALE_ORDER.countries[appLanguage],
  ]);
  if (Array.isArray(excludes) && excludes.length > 0) {
    const excludeItems = sortedOptions.filter((current) =>
      excludes.includes(current.id)
    );
    sortedOptions = sortedOptions.filter(
      (current) => !excludes.includes(current.id)
    );
    sortedOptions.push(...excludeItems);
  }
  return sortedOptions;
};

export const getRegionsByCountryCode = async ({ countryCode, appLanguage }) => {
  try {
    const apiResponse = await GenericDataAPI.getRegionsByCountryCode({
      countryCode,
    });
    if (!apiResponse.success) {
      throw new Error("Unable to get country regions");
    }
    let sortKey = "regionName";
    if (appLanguage !== APP_LANG.EN) {
      sortKey = `${sortKey}_${appLanguage}`;
    }
    const dataset = sortBy(apiResponse.body, [sortKey]);
    return dataset;
  } catch (error) {
    AppLogger.exception(error);
    throw error;
  }
};

export const checkSensitiveData = async ({ content }) => {
  try {
    const response = await GenericDataAPI.checkSensitiveData({ content });
    return response.statusCode === HttpCode.OK;
  } catch (error) {
    AppLogger.exception(error);
    throw error;
  }
};

export const getCommunitiesByReligionId = async ({
  religionId,
  setCommunities,
}) => {
  const response = await GenericDataAPI.getCommunitiesByReligionId({
    religionId,
  });
  if (response.success && isFunction(setCommunities)) {
    setCommunities(response.body);
  }
  return response;
};

export const fetchCommunitiesByReligionId =
  ({ religionId, setCommunities }) =>
  async () => {
    const response = await GenericDataAPI.getCommunitiesByReligionId({
      religionId,
    });
    if (response.success && isFunction(setCommunities)) {
      setCommunities(response.body);
    }
    return response;
  };

export const getPricingTiers = async () => {
  try {
    const response = await GenericDataAPI.getPricingTiers();
    if (!response.success) {
      throw new Error("Unable to get country regions");
    }
    return response;
  } catch (error) {
    AppLogger.exception(error);
    throw error;
  }
};

export const getGenericReportReasons = async () => {
  try {
    const response = await GenericDataAPI.getGenericReportReasons();
    if (!response.success) {
      throw new Error("Unable to get Report Reasons");
    }
    return response;
  } catch (error) {
    AppLogger.exception(error);
  }
};

export const postVipRequest = async ({ formData }) => {
  try {
    const response = await GenericDataAPI.addVipRequest({ formData });
    if (!response.success) {
      throw new Error("Unable to create vip request");
    }
    return response;
  } catch (error) {
    throw error;
  }
};
