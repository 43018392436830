import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import withLanguageMigrator from "../../v2/hoc/language-migrator";

const Matching = ({ lng }) => {
  const [selected, setSelected] = useState(1);
  const [expandArr, setExpandArr] = useState([]);

  const QNA = [
    {
      id: 1,
      quee: "Can I view the advertisements on the website by filtering as I prefer?",
      ques: "මාගේ අපේක්ෂාවන්ට ගැලපෙන ලෙස දැන්වීම් දැක බලා ගත හැකිද?",
      answe:
        "Yes. You can find Ads as you like. You can search for the Ads you like by using the filtering option on the left side of the website. Furthermore, you can save the preferred search criteria by pressing “Save” below it if you want.",
      answs:
        "ඔව්. ඔබ කැමති අයුරින් දැන්වීම් සොයා ගත හැකියි. වෙබ් අඩවියේ වම් පස ඇති පෙරහන් තුලින් ඔබට ගැලපෙන පරිදි වෙනස් කර තහවුරු කිරීම මගින් ඔබ කැමති දැන්වීම් දැක ගත හැක. ඔබට අවශ්‍ය නම් එහි යට ඇති “තහවුරු කරන්න” යන ඔබා සුපුරුදු සෙවුම් නිර්ණායක ලෙස ගබඩා කල හැක.",
    },
    {
      id: 2,
      quee: "How do I send interest to an Ad I like?",
      ques: "මා කැමති දැන්වීමක් සදහා කැමැත්ත පල කරන්නේ කෙසේද?",
      answe:
        "Select the advertisement you like and click the ‘More info’ button on that Ad to see more of his / her details. You can then send an interest request by clicking the 'Show interest' button.",
      answs:
        "ඔබ කැමැත්තක් දක්වන දැන්වීමෙහි ‘වැඩි විස්තර’ යන්න තෝරා ඉදිරියට ගිය විට ඔහුගේ/ඇයගේ වැඩි විස්තර දැක බලා ගත හැකි අතර එහි ඇති ‘කැමැත්ත පලකරන්න’ යන්න තෝරා ඉල්ලීමක් යැවිය හැක.",
    },
    {
      id: 3,
      quee: "How can I see pictures of the other party?",
      ques: "දැන්වීම් සමග ඇති පින්තූර දැක බලා ගත හැක්කේ කෙසේද?",
      answe:
        "When you send an interest through 'Show Interest' option, you can see the other party's pictures once they accept your request. But to see a picture, both parties have to upload pictures to the advertisement, if one of the parties does not include the picture then both parties will not be able to see the pictures.",
      answs:
        "ඔබ ‘කැමැත්ත පෙන්වන්න’ හරහා ඉල්ලීමක් යැවූ විට අනෙක් පාර්ශවයේ පින්තූර දැක බලා ගත හැකියි. පින්තුර දැක ගැනීමට දෙපාර්ශවයම විසින් දැන්වීම් තුල පින්තූර ඇතුලත් කර තිබිය යුතු අතර එක් අයකු හෝ ඇතුලත් කර නොමැතිනම් දෙදෙනාටම පින්තූර පෙන්වනු නොලැබේ.",
    },
    {
      id: 4,
      quee: "How can I see all the details of someone I have sent a request to?",
      ques: "මා කැමැත්ත පල කර ඉල්ලීමක් යැවූ අයෙකුගේ සියලුම විස්තර දැක ගත හැක්කේ කෙසේද?",
      answe:
        "After the other party accepts your interest request, you will be able to see all the details in the ad including his/her personal details like full name, phone number, date of birth, picture, and horoscope details.",
      answs:
        "අනෙක් පාර්ශවය විසින් ඔබගේ කැමැත්ත පල කර යැවූ ඉල්ලීම පිළිගත් පසු ඔබට ඔහුගේ/ඇයගේ සම්පූර්ණ නම, දුරකථන අංකය, උපන් දිනය, පින්තුර, කේන්දර සටහන් විස්තර යන පෞද්ගලික විස්තර ද සහිතව දැන්වීමෙහි ඇති සියලුම විස්තර දැක ගත හැකි වේ.",
    },
    {
      id: 5,
      quee: "How do I notify the other party about my interest request?",
      ques: "මාගේ කැමැත්ත පල කර යැවූ ඉල්ලීමක් අනෙක් පාර්ශවයට දැනුම් දෙන්නේ කෙසේද?",
      answe:
        "When you send an interest request to the other party, we will inform him/her by sending a text message or an email for the mobile number and the email address that he/she verified with us when registering. And he/she can log into our website and see your interest request by clicking the ‘Interest received’ button on his/her advertisement.",
      answs:
        "ඔබ යම් අයකුට කැමැත්ත පළකර ඉල්ලීමක් යවන අවස්ථාවේදී, එය කෙටි පණිවිඩයක් හෝ ඊමේල් පණිවිඩයක් මගින් අනෙක් පාර්ශවයට අප විසින් දැනුම් දීමක් සිදු කරනු ලබන අතර ඔහුගේ/ඇයගේ ගිණුමේ ඇති 'කැමැත්ත එවූ අය' යන්න හරහා ඔබ විසින් යැවූ ඉල්ලීමට සම්බන්ද විය හැකිය.",
    },
    {
      id: 6,
      quee: "Is it possible to add more details and send a message when submitting a request?",
      ques: "කැමැත්ත පල කර ඉල්ලීමක් යවන අවස්ථාවේදී එම ඉල්ලීම සමග වැඩි විස්තරයක් එක් කර පණිවිඩයක් යැවිය හැකිද?",
      answe:
        "Yes. When you send a request to an Ad that you like, you can send a message with a short description in the 'Message' section and after he/she accepts your request, also you can decide whether to share your phone number or not to him/her after accepting the interest request.",
      answs:
        "ඔව්. ඔබ කැමති දැන්වීමක් වෙත ඉල්ලීමක් යවන අවස්ථාවේදී ‘පණිවිඩය’ කොටසෙහි කෙටි විස්තරයක් සදහන් කර පණිවිඩයක් යැවීමේ හැකියාව ඇති අතර ඔහු/ඇය ඔබගේ ඉල්ලීම පිලි ගත් පසු ඔබගේ දුරකථන අංකය ඔහුට/ඇයට දිස්වන්නේ ද යන්න තීරණය කර ඔබගේ ඉල්ලීම යැවිය හැකිය.",
    },
    {
      id: 7,
      quee: "If an interest request sent by me is ignored by the other party or withdrawn due to my own negligence, is it possible to send a request to the same person again?",
      ques: "මා විසින් යවන ලද කැමැත්ත පල කර යැවූ ඉල්ලීමක් අනෙක් පාර්ශවය විසින් නොසලකා හැරීමක් හෝ මා විසින්ම අතපසුවීමකින් ඉල්ලා අස් කිරීමක් සිදු වුවහොත් නැවත එම දැන්වීමටම ඉල්ලීමක් යැවීමේ හැකියාවක් තිබේද?",
      answe:
        "You will not be able to send a request to the same Ad again, and the other party also will not be able to send a request to you again.",
      answs:
        "එම කිසිදු අවස්ථාවකදී නැවත ඔබට එම දැන්වීමට ම ඉල්ලීමක් යැවීමේ හැකියාවක් නොමැති අතර අනෙකුත් පාර්ශවයට ද ඔබගේ දැන්වීම වෙත නැවත ඉල්ලීමක් එවීමට හැකියාවක් නොමැත.",
    },
    {
      id: 8,
      quee: "How to contact a matched profile?",
      ques: "ගැලපී ඇති දැන්වීමක් හා පණිවිඩ හුවමාරු කරගන්නේ කෙසේද?",
      answe:
        "By clicking on the 'Matches' button in your ad, you can see the Ads of the parties who are matched with you, and you can go to the Ad you want to contact and contact them through the phone numbers and email addresses shown in his/her ad. Messages can also be sent through the available messaging service.",
      answs:
        "ඔබගේ දැන්වීමෙහි ඇති “ගැලපීම්” යන බොත්තම ඔබා, ඔබ සමග ගැලපී ඇති පාර්ශවයන්ගේ දැන්වීම් දැක බලා ගත හැකි අතර ඔබට සම්බන්ද වීමට අවශ්‍ය දැන්වීම වෙත ගොස් ඔහුගේ/ඇයගේ දැන්වීම තුල පෙන්වන දුරකථන අංක, ඊමේල් ලිපිනයන් ඔස්සේ සම්බන්ද විය හැකි අතර වෙබ් අඩවිය තුල අප ලබා දී ඇති පණිවිඩ හුවමාරු කරගැනීමේ සේවාව ඔස්සේද පණිවිඩ යැවිය හැක.",
    },
    {
      id: 9,
      quee: "What should I do if a request received to me is not what I expected or if I do not like the request?",
      ques: "මා හට කැමැත්ත පල කර එවූ ඉල්ලිමක් මා බලාපොරොත්තු වූවක් නොවන්නේ  නම් හෝ මා එම ඉල්ලීමට අකමැති නම් කුමක් කල යුතුද ?",
      answe:
        "If you do not like the request or if the request is not what you expected, you can ignore it and then the other party will not be able to send you a request again.",
      answs:
        "ඔබ එම ඉල්ලීමට අකමැති නම් හෝ එම ඉල්ලීම ඔබගේ අපේක්ෂාවක් නොවන්නේ නම් ඔබට එය නොසලකා හැරීමේ හැකියාව ඇති අතර ඉන්පසු අනෙක් පාර්ශවයට නැවත වරක්  ඔබට ඉල්ලීමක් එවීමේ හැකියාවක් නොමැති වේ.",
    },
    {
      id: 10,
      quee: "Can I limit the requests I receive based on my preferences?",
      ques: "මා කැමති නිර්ණායක මත මා හට ලැබෙන ඉල්ලීම් සිමා කල හැකි ද?",
      answe:
        "Yes. You can limit the requests based on the criteria of age, ethnicity, religion, civil status, height, and country of residence of the party sending you requests.",
      answs:
        "ඔව්. ඔබ වෙත ඉල්ලීම් එවන පාර්ශවයේ වයස, ජාතිය, ආගම, සිවිල් තත්ත්වය, උස සහ පදිංචි රට යන නිර්ණායක වලට අනුව ඔබට ගැලපෙන ලෙස වෙනස් කර එම ඉල්ලීම් එවීම් සිමා කල හැක.",
    },
    {
      id: 11,
      quee: "What if someone sends inappropriate messages or an Ad appears to be fake?",
      ques: "යම් අයෙකු විසින් නොගැලපෙන ලෙස පණිවිඩ එවන්නේ නම් හෝ යම් දැන්වීමක් ව්‍යාජ දැන්වීමක් යැයි හැගේ නම් ඒ සදහා කුමක් කල යුතුද?",
      answe:
        "In such cases, you have the option to report the Ad and opt out of the ad by clicking “Unmatch”. When reporting an Ad, by stating the specific reason for reporting it, it is easier for us to look into it in the future and take the necessary steps.",
      answs:
        "එවැනි අවස්ථාවන් වලදී ඔබට එම දැන්වීම වාර්තා කිරීමේ හැකියාව ඇති අතර “නොගලපන්න” ඔස්සේ එම දැන්වීම් නොගලපා හැරීමේ හැකියාව තිබේ. යම් දැන්වීමක් වාර්තා කිරීමේදී එය වාර්තා කිරීමට පාදක වූ නිශ්චිත හේතුව සදහන් කිරීම තුලින් ඉදිරියේදී ඒ පිලිබදව සොයා බලා අප විසින් අවශ්‍ය පියවර ගැනීමේදී පහසුවක් වේ.",
    },
    {
      id: 12,
      quee: "What is a “Top Ad” ?",
      ques: "“Top Ad” යනු කුමක්ද?",
      answe: (
        <>
          By 'Top Ad' your Ad can be shown higher in the search results and
          increase the visibility of your Ad. This service is valid for one week
          and will include an additional charge. See{" "}
          <a href="https://www.poruwa.lk/account" target="_blank">
            https://www.poruwa.lk/account
          </a>{" "}
          page for more details.
        </>
      ),
      answs: (
        <>
          ඔබගේ දැන්වීම “Top Ad” කිරීම තුලින් සෙවුම් ප්‍රතිඵල වල ඉහලින් පෙන්විය
          හැකි අතර මගින් ඔබගේ දැන්වීමට ඇති ආකර්ෂණය වැඩි වේ. මෙම සේවාව සතියක්
          සදහා වලංගු වන අතර අමතර ගාස්තුවක් අය වේ. ගාස්තු විස්තර සදහා{" "}
          <a href="https://www.poruwa.lk/account" target="_blank">
            https://www.poruwa.lk/account
          </a>{" "}
          පිටුව බලන්න
        </>
      ),
    },
    {
      id: 13,
      quee: "What are the Ads displayed as “Suggested for you”?",
      ques: "“Suggested for you” යනුවෙන් පෙන්වන දැන්වීම් මොනවාද ?",
      answe:
        "We display Ads with similar details to your Ad and facilitate finding a partner/partner that matches your criteria more quickly through this service.",
      answs:
        "ඔබගේ දැන්වීමෙහි ඇති විස්තර වලට සමාන විස්තර ඇති දැන්වීම් එමගින් අප පෙන්වන අතර මෙම සේවාව තුලින් ඔබගේ නිර්ණායක වලට ගැලපෙන පරිදි වඩාත් ඉක්මනින් සහකරුවකු/සහකාරියකු සොයා ගැනීමේ ක්‍රියාවලිය පහසු කරයි.",
    },
    {
      id: 14,
      quee: "How do I know when the Ad I expect to send a response to, has recently come online?",
      ques: "මා විසින් ප්‍රතිචාරයක් යැවීමට අපේක්ෂා කරන දැන්වීම අවසන් වරට දැන්වීම පිරික්සීමට පැමිණ ඇති වේලාව දැනගන්නේ කෙසේද?",
      answe:
        "When you click “More details” on the selected Ad, we will confirm that the user has checked the Ad on the date recorded as it’s last online date. You can enable or disable the last online status of your Ad as you wish and when disabled, the date you were last online in the ad will not be shown to other parties",
      answs:
        "ඔබ තෝරාගත් දැන්වීම තුලට “වැඩි විස්තර” ඔබා ගිය පසු එහි අවසාන ඔන්ලයින් දිනය ලෙස සටහන් වන දිනය තුල එම පරිශීලකයා දැන්වීම පරික්ශා කර ඇති බවට අපි තහවුරු කරන්නෙමු. ඔබගේ දැන්වීමෙහි අවසාන ඔන්ලයින් දිනය ඔබට අවශ්‍ය පරිදි සක්‍රීය හෝ අක්‍රීය කල හැකි අතර අක්‍රීය කර ඇති අවස්ථාවන් වලදී ඔබ අවසන් වරට දැන්වීම පරික්ෂා කල ඇති දිනය අනෙක් පාර්ශවයන්ට නොපෙන්වයි.",
    },
    {
      id: 15,
      quee: "How do I know if the other party has seen a message I sent through the website?",
      ques: "මා වෙබ් අඩවිය ඔස්සේ යැවූ පණිවිඩයක් අනෙක් පාර්ශවය දැක ඇත්තේද යන්න දැනගන්නේ කෙසේද?",
      answe:
        "If a blue “✔️” sign appears next to your message, it means that the other party has seen your message.",
      answs:
        "ඔබ යැවූ පණිවිඩයත් සමග නිල් පාටින් “✔️” ලකුණක් දිස් වන්නේ නම් එයින් අදහස් වන්නේ අනෙක් පාර්ශවය ඔබගේ පණිවිඩය දැක ඇති බවයි.",
    },
    {
      id: 16,
      quee: "Why aren't the pictures of a matched profile is not showing?",
      ques: "මාගේ දැන්වීම සමග ගැලපුණු දැන්වීමක පින්තූර නොපෙන්වන්නේ ඇයි?",
      answe:
        "If one of the two matched Ads does not have pictures attached to the Ad, then neither image will appear. If you have not added a picture to your Ad, you can add a picture by going to “Edit My Ad”.",
      answs:
        "ගැලපි ඇති දැන්වීම් දෙකෙන් එක් අයකු හෝ දැන්වීමට පින්තූරයක් එක්කොට නොමැති අවස්ථාවලදී දෙදෙනාටම පින්තූර දිස්වන්නේ නැත. ඔබ දැන්වීමට පින්තූරයක් එක කර නොමැති නම් “මගේ දැන්වීම වෙනස් කරන්න” වෙත ගොස් පින්තූරයක් එකතු කල හැක.",
    },
    {
      id: 17,
      quee: "Why isn't the phone number shown in an ad that matches my Ad?",
      ques: "මාගේ දැන්වීම සමග ගැලපුණු දැන්වීමක දුරකථන අංකය නොපෙන්වන්නේ ඇයි?",
      answe:
        "In cases where the other party has also hidden his/her phone number in the matched Ads, you will not see the phone number in that Ad. You can press the “Message” button and send him/her a message and request further information. Your mobile number also will not be shared with them, until that party shares their mobile number with you.",
      answs:
        "අනෙක් පාර්ශවය විසින් ඔහුගේ/ඇයගේ දුරකථන අංකය ගැළපු දැන්වීම් වලට ද සගවා ඇති අවස්ථාවන් වලදී ඔබට එම දැන්වීමෙහි දුරකථන අංකය දිස්වන්නේ නැත. එවිට “පණිවිඩ” බොත්තම ඔබා ඔහුට/ඇයට පණිවිඩයක් යැවිය හැක.එම පාර්ශවය විසින් ඔවුන්ගේ දුරකථන අංකය ඔවුන්ගේ දැන්වීමෙහි ඔබට පෙන්වන තුරු ඔබගේ දුරකථන අංකයද ඔවුන්ට නොපෙන්වයි.",
    },
  ];
  return (
    <div>
      <div className="new-faq-mobile">
        {QNA.map((qna) => (
          <div className="new-faq-div">
            <div className="new-faq-col">
              <div
                onClick={() => {
                  if (!expandArr.includes(qna.id)) {
                    setExpandArr((current) => [...current, qna.id]);
                  } else {
                    setExpandArr(
                      expandArr.filter((current) => current !== qna.id)
                    );
                  }
                }}
                className="new-faq-ques"
              >
                <span className="new-faq-ques-wrapper">
                  {lng === true ? <>{qna.quee}</> : <span>{qna.ques}</span>}
                </span>
                {expandArr.includes(qna.id) ? (
                  <i class="fas fa-chevron-down faq-drawer-icon"></i>
                ) : (
                  <i class="fas fa-chevron-right faq-drawer-icon"></i>
                )}
              </div>
              <p
                className={
                  expandArr.includes(qna.id)
                    ? "new-faq-ans-expand"
                    : "new-faq-ans"
                }
              >
                {lng === true ? (
                  <React.Fragment>{qna.answe}</React.Fragment>
                ) : (
                  <span>{qna.answs}</span>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withLanguageMigrator(Matching);
