import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import RouteConfigHandler from "../../templates/route-config-handler";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import NotificationPreferences from "./notification-preferences";
import OfflineResponses from "./offline-responses";
import AppFooter from "../../templates/footer";
import NotificationPreferenceDomain from "../../features/notification-preferences";
import Config from "../../config";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { scrollIntoElement, scrollToTop } from "../../utils";

const { OFFLINE_RESPONSES_VISIBILITY } = Config;

const SettingsPage = ({ routeId }) => {
  const { t } = useTranslation();
  const [notificationTypes, setNotificationTypes] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (isEmpty(notificationTypes)) {
      NotificationPreferenceDomain.getPushNotificationAllowedTypes().then(
        (preferences) => {
          setNotificationTypes(preferences);
        }
      );
    }
  }, [notificationTypes]);

  useEffect(() => {
    scrollToTop();
    const state = location.state;
    setTimeout(() => {
      if (state) {
        scrollIntoElement({ id: state });
      }
    }, 1000);
  }, [location]);

  return (
    <RouteConfigHandler id={routeId}>
      <Grid
        container
        direction="column"
        className="page-layout row-gap-16 padding-bottom-32"
        alignItems="center"
      >
        <Typography variant="h6" className="margin-top-16">
          {t("common.settings")}
        </Typography>
        <NotificationPreferences notificationTypes={notificationTypes} />
        {OFFLINE_RESPONSES_VISIBILITY && <OfflineResponses />}
      </Grid>
      <AppFooter />
    </RouteConfigHandler>
  );
};

export default SettingsPage;
