import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import ExternalHeader from "./detail-page/InterestExternalHeader";

import TimeLine from "./detail-page/ProfileTimeLine";
import Connect from "./detail-page/InterestProfileConnect";
// import Personal from "./detail-page/InterestPersonal";
// import Parent from "./detail-page/InterestParent";
// import Alerts from "./detail-page/InterestAlert"

import { setPreLoading } from "../../actions/setPreLoading";
import { CircularProgress } from "@material-ui/core";
import { removePost } from "../../actions/removePost";
import { fetchInterestPost } from "../../actions/fetchInterestPost";
import { getInterestPosts } from "../../actions/getInterestPosts";
import {markInterestSeen} from "../../actions/markInterestSeen";
import { fetchNotificationCount } from "../../actions/fetchNotificationCount";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { getInnerHeight } from "./methods/getInnerHeight";
import "../../styles/received-int-profile.css";
import Info from "../../images/info.png";
import HeaderSection from "../../v2/templates/profile-view/header-section";
import PersonalInformation from "../../v2/templates/profile-view/information-section/personal-information";
import FamilyInformation from "../../v2/templates/profile-view/information-section/family-information";
import StatusSection from "../../v2/templates/profile-view/status-section";
import { Grid } from "@material-ui/core";
import HoroscopeInformation from "../../v2/templates/profile-view/information-section/horoscope-information";

function ReceivedInterest(props) {
  const [preLoading, setPreLoading] = useState(false);
  const [externalPost, setExternalPost] = useState([]);
  const [isExternal, setIsExternal] = useState(false);
  const [post, setPost] = useState([]);
  // const [timeLine, setTimeLine] = useState([]);
  const [isEmptyPost, setIsEmptyPost] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [likeMessage, setLikeMessage] = useState("");
  const [showPhotos, setShowPhotos] = useState(false);
  const [receivedInterestImages, setReceivedInterestImages] = useState([]);
  const [sendingPostId, setSendingPostId] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [gender, setGender] =  useState("");
  const [intId, setIntId] =  useState("");
  const [height, setHeight] =  useState("");
  const [age, setAge] =  useState("");
  const [profession, setProfession] =  useState("");
  const [profession_si, setProfession_si] =  useState("");
  const [residentCity, setResidentCity] =  useState("");
  // const [hasImages, setHasImages] = useState(false)
  // const [hasHoroscopeData, setHasHoroscopeData] = useState(false)
  // const [lastOnline, setLastOnline] = useState("")

  useEffect(() => {
    setPreLoading(true);
    setIsExternal(false);
    setMessageModal(false);
    if (!props.isSent) {
      // props.getInterestPosts();
      axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem(
          "postId"
        )}/ext_interests`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        let obj = res.data.filter(
            (el) =>
              (el.id === props.intId)
          )
          if (obj.length > 0){
            setIsExternal(true)
            setExternalPost(obj[0]);
          } else {
            props.markInterestSeen(props.intId);
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/v1/account/interests/${props.intId}/seen
              `,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                  },
                }
              )
              .then((res) => {
                props.fetchNotificationCount();
              })
              .catch((err) => {
                console.log(err);
              });
          }
          setPreLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    }

      axios
      .get(
        `${
          process.env.REACT_APP_API_URL
          }/v2/posts/${localStorage.getItem("postId")}/interests/${
            props.intId
          }
          `,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.match) {
            props.history.push("/messages?id="+props.intId);
          } else if (res.data.interest) {
            setIsEmptyPost(false);
            setPreLoading(false);
            setPost(res.data.interest);
            // setTimeLine(res.data.interest.timeline);
            setLikeMessage(res.data.interest.likeMessage);
            setShowPhotos(res.data.interest.showPhotos);
            setReceivedInterestImages(res.data.interest.images);
            setSendingPostId(res.data.interest.post.id);
            setPreLoading(false);
            setDisplayName(res.data.interest.post.personalInfo.displayName);
            setGender(res.data.interest.post.personalInfo.gender);
            setIntId(res.data.interest.id);
            setHeight(res.data.interest.post.personalInfo.height);
            setAge(res.data.interest.post.personalInfo.age);
            setProfession(res.data.interest.post.personalInfo.profession.name === "Other" ?
              res.data.interest.post.personalInfo.professionOther
              :
              res.data.interest.post.personalInfo.profession.name);
            setProfession_si(res.data.interest.post.personalInfo.profession.name === "Other" ?
              res.data.interest.post.personalInfo.professionOther
              :
              res.data.interest.post.personalInfo.profession.name_si);
            setResidentCity(res.data.interest.post.personalInfo.residentCity);
            // setLastOnline(res.data.interest.post.lastOnline);
            // setHasImages(res.data.interest.post.hasImages);
            // setHasHoroscopeData(res.data.interest.post.hasHoroscopeData);
          } else {
            props.history.push("/interests");
          }
        })
        .catch((err) => {
          setIsEmptyPost(true);
          console.log(err)
        });
  }, [props.intId]);

  const handleAcceptMessageModal = () => {
    setMessageModal(true);
  };

  const handleModalClose = () => {
    setMessageModal(false);
  }

  const handleShowInterest = (id, message, showPhone, showPhotos = null) => {
    if (localStorage.getItem("auth_token")) {
      const data = `likedPostId=${id}&message=${message}&showPhone=${showPhone}&showPhoto=${showPhotos}`;
      // this.setState({ loading: true });

      axios
        .post(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/show_interest`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          setMessageModal(false);
          window.location.href="/messages";
        })
        .catch((err) => {
          console.log(err);

          if (err.response.data.code === 1801) {
            // this.setState({
            //   sensitiveDataError: this.state.lng
            //     ? "Your message contains sensitive personal information such as email addresses, phone numbers or web URLs. Please remove them and retry."
            //     : "ඔබගේ පණිවිඩයේ විද්‍යුත් ලිපින, දුරකථන අංක හෝ වෙබ් URL වැනි සංවේදී පුද්ගලික තොරතුරු අඩංගු වේ. කරුණාකර ඒවා ඉවත් කර නැවත උත්සාහ කරන්න.",
            // });
          }
          // this.setState({ loading: false });
        });
    }
  };

  return (
    <>
    {preLoading ?
      <div style={{margin: 'auto', color: '#c19d0d'}}>
        <CircularProgress style={{color: '#c19d0d'}} className="loading-circle" />
      </div>
      :
      <div
      className={
        "received-profile-detail-div show-view"
      }
      id="received-profile-detail-div-unique"
    >
      {!isExternal ?
        !isEmptyPost ?
          <div className="inner-div">
            <span
              className="dis-back-btn back-btn-msg"
              onClick={() => props.history.goBack()}
              style={{ margin: "15px 15px 15px 0" }}
            >
              <i className="fas fa-angle-left"></i>{" "}
              {props.lng ? "Back" : "ආපසු යන්න"}
            </span>
            {/* {!messageModal && <Header lng={props.lng} intId={props.intId} isInterestActive={true} postDetails={post} isSent= {props.isSent ? true : false}/>} */}

            {/* Refactored sections */}
            {!messageModal && (
                  <Grid container direction="column" style={{ rowGap: 20, marginBottom: 20 }}>
                    <HeaderSection
                      data={post?.post}
                      interestData={post}
                      variant="INTEREST_VIEW"
                    />
                    <StatusSection data={post?.post} interestData={post} />
                  </Grid>
                )}

            {/* {!messageModal &&
              <Alerts
                lng={props.lng}
                lastOnline={lastOnline}
                hasImages={hasImages}
                hasHoroscopeData = {hasHoroscopeData}
                isMatchActive={false}
                preLoading={preLoading}
              />
            } */}
            {post.ignored ? null : post.timeline &&
              post.timeline.filter(
                (el) =>
                  (el.eventType === "LIKED" && el.actionBy === "SELF") ||
                  el.eventType === "LIKE_REFUSED"
              ).length > 0 ? null : (
                <Connect
                  post={post}
                  lng={props.lng}
                  isMatchActive={false}
                  handleAcceptMessageModal={handleAcceptMessageModal}
                  messageModal={messageModal}
                  likeMessage={likeMessage}
                  showPhotos={showPhotos}
                  receivedInterestImages = {receivedInterestImages}
                  handleShowInterest = {handleShowInterest}
                  sendingPostId={sendingPostId}
                  displayName={displayName}
                  handleModalClose={handleModalClose}
                  intId={intId}
                  height={height}
                  age={age}
                  profession={profession}
                  profession_si={profession_si}
                  residentCity={residentCity}
                  gender={gender}
                />
            )}

            {!messageModal && <TimeLine lng={props.lng} post={post} handleAcceptMessageModal={handleAcceptMessageModal} />}

            {(messageModal && post.ignored && post.autoIgnored) &&
              <Connect
                post={post}
                lng={props.lng}
                isMatchActive={false}
                handleAcceptMessageModal={handleAcceptMessageModal}
                messageModal={messageModal}
                likeMessage={likeMessage}
                showPhotos={showPhotos}
                receivedInterestImages = {receivedInterestImages}
                handleShowInterest = {handleShowInterest}
                sendingPostId={sendingPostId}
                displayName={displayName}
                handleModalClose={handleModalClose}
                intId={intId}
                height={height}
                age={age}
                profession={profession}
                profession_si={profession_si}
                residentCity={residentCity}
                gender={gender}
              />

            }

            {/* {(!messageModal && post.post && post.post.personalInfo.civilStatus) ? (
              <Personal lng={props.lng} preLoading={preLoading} post={post} personalInfo={post.post.personalInfo} />
            ) : null}

            {(!messageModal && post.post && post.post.parentInfo) ? (
              <Parent lng={props.lng} preLoading={preLoading} post={post} father={post.post.parentInfo.filter((el) => el.type === "FATHER")[0]} mother={post.post.parentInfo.filter((el) => el.type === "MOTHER")[0]} />
            ) : null} */}

            {/* Refactored sections */}
            {!messageModal && (
                  <Grid container direction="column" style={{rowGap: 20}}>
                    <PersonalInformation data={post?.post} />
                    <FamilyInformation data={post?.post} />
                    <HoroscopeInformation data={post?.post}/>
                  </Grid>
                )}

          </div>
          :
          <div>
            <div className="disable-div" style={{ marginTop: 0 }}>
              <p align="center" style={{ marginBottom: 0 }}>
                <img
                  src={Info}
                  alt="info"
                  style={{ width: 50, opacity: 0.7 }}
                />
              </p>
              <p className="message">
                {props.lng ? "Ad is unavailable" : "දැන්වීම නොමැත"}
              </p>
              <p align="center" className="desc">
                {props.lng ? (
                  "This advertisement has been taken down by its owner."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-14">
                    මෙම දැන්වීම එහි හිමිකරු විසින් ඉවත් කරගෙන ඇත.
                  </span>
                )}
              </p>
            </div>
          </div>
        :
        <div className="inner-div">
          <ExternalHeader lng={props.lng} preLoading={preLoading} post={externalPost} />
        </div>
      }
    </div>

    }
    </>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setPreLoading, removePost, fetchInterestPost, getInterestPosts, markInterestSeen, fetchNotificationCount },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    interestPosts: state.matches,
    isEmptyPost:
      Object.keys(state.matches.post).length === 0 &&
      state.matches.post.constructor === Object,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(ReceivedInterest));
