import { Grid, CircularProgress } from "@material-ui/core";
import React from "react";
import AppFooter from "../../footer";

import SplashViewImg from "../../../../images/splash--view-img.png";

import "./style.css";

const SplashView = () => {
  return (
    <>
      <Grid
        container
        direction="row"
        className="splash-view"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          md={8}
          lg={6}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="content-container"
        >
          <CircularProgress />
          <img
            src={SplashViewImg}
            alt="poruwa.lk"
            className="splash-view-img"
          />
        </Grid>
      </Grid>
      <AppFooter />
    </>
  );
};

export default SplashView;
