import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import CoupleIcon from "../../../../../images/coupleIcon.png";
import CuratedIcon from "../../../../../images/curatedIcon.png";
import SatisfactionIcon from "../../../../../images/satisfactionIcon.png";
import IconText from "./icon-text";

const UspView = () => {
  const isMobileView = useMediaQuery("(max-width:700px)");

  return (
    <Grid container direction="column" className="usp-view" wrap="nowrap">
      <Grid container direction="column" alignItems="center">
        <img
          src="https://static.poruwa.lk/images/payment/premium-txt.png"
          alt="premium-text"
          className="premium-img"
        />
        <Typography
          variant={isMobileView ? "h5" : "h4"}
          className="primary-txt accent-font"
        >
          BENEFITS
        </Typography>
      </Grid>
      <Grid container direction="row">
        <IconText text="Dedicated Consultation" icon={CoupleIcon} />
        <IconText text="Higher Success Rate" icon={SatisfactionIcon} />
        <IconText text="Curated Matches" icon={CuratedIcon} />
      </Grid>
    </Grid>
  );
};

export default UspView;
