import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import axios from "axios";
// import qs from "qs";
import { Button, CircularProgress } from "@material-ui/core";
import ReactCodeInput from "react-verification-code-input";
import ComErrorAlert from "../create-flow/ComErrorAlert";
import CallUsToCreateAccount from "../common/CallUsToCreateAccount";
import BlockedUserModal from "../modals/BlockedUserModal";
// import ReCAPTCHA from "react-google-recaptcha";
import { isEmpty } from "lodash";

import { handleIntBtns } from "../../actions/handleIntBtns";
import { fetchAllInterest } from "../../actions/fetchAllInterest";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { extractUtmCookies } from "../../utils/extractor";
import {
  initializeApp,
  getAuthUserProfile,
  getAuthTempProfile,
} from "../../v2/features/application";
import Config from "../../v2/config";
import { setHomePageShadowedCriteria } from "../../v2/store/actions/home-page";
import AuthDomain from "../../v2/features/auth";
// import UserApi from "../../v2/services/api/user";
import { setAppCreateFlowInitialized } from "../../v2/store/actions/application";

var timer;
var refCode = "";

class RegisterAcc extends Component {
  state = {
    name: "",
    regEmail: "",
    phone: "",
    password: "",
    isShowPwd: false,
    showFirstView: false,
    isUsingPhone: true,
    showCountryCodes: false,
    countries: [],
    search: "",
    code: "",

    // phone verification
    phoneId: "",
    showPhoneVerification: false,
    timeCountDown: 30,
    showSendOptCode: false,
    sendAgainError: "",
    toManyAttempts: false,
    getAuotOtp: false,

    // error
    nameError: "",
    regEmailError: "",
    phoneError: "",
    passwordError: "",
    createdByError: "",
    authError: "",
    showBlockedUserModal: false,

    // loading
    loading: false,
    errorAlert: false,
    errorCode: "",
    token: "",
    captchaSecret: "",
    captchaEnable: false,
    btndisable: false,
  };

  componentDidMount = () => {
    this.loadScripts();
    this.setState({ captchaSecret: process.env.REACT_APP_CAPTCHA_KEY });

    this.setState({
      code: this.props.appConfig.dialCode
        ? `+${this.props.appConfig.dialCode}`
        : Config.DIAL_CODE,
    });

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // ref code
    const findRef = document.cookie.match(
      new RegExp("(^| )" + "ref" + "=([^;]+)")
    );

    if (findRef !== null) {
      refCode = findRef[2];
    }

    //event to auto complete OTP

    if ("OTPCredential" in window) {
      window.addEventListener("DOMContentLoaded", (e) => {
        const ac = new AbortController();
        navigator.credentials
          .get({
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            if (otp.code) {
              this.setState({ getAuotOtp: true });
              this.setState({ optCode: otp.code, verificationError: false });
              this.verifyOTP();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  loadScripts = () => {
    // captcha
    const captchaScript = document.createElement("script");
    captchaScript.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`;
    captchaScript.async = true;
    document.body.appendChild(captchaScript);
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.appInitialized && this.props.appInitialized) {
      this.setState({
        code: this.props.appConfig.dialCode
          ? `+${this.props.appConfig.dialCode}`
          : Config.DIAL_CODE,
      });
    }
  };

  componentWillUnmount = () => {
    if ("OTPCredential" in window) {
      window.removeEventListener("DOMContentLoaded", (e) => {
        const ac = new AbortController();
        navigator.credentials
          .get({
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            if (otp.code) {
              this.setState({ getAuotOtp: true });
              this.setState({ optCode: otp.code, verificationError: false });
              this.verifyOTP();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }

    const scriptSelector =
      "script[src='https://www.google.com/recaptcha/api.js?render=" +
      process.env.REACT_APP_CAPTCHA_KEY +
      "']";
    const script = document.querySelector(scriptSelector);
    if (script) {
      script.remove();
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  handleCountryCode = (code) => {
    this.setState({ code, showCountryCodes: false });
  };

  handlePhone = (e) => {
    const str = e.target.value.toString();
    this.setState({ phone: parseInt(str, 10), phoneError: "" });
  };

  // validate
  validate = () => {
    let phoneError = "";
    if (this.state.phone === "") {
      phoneError = this.props.lng
        ? "Phone number is required"
        : "දුරකථන අංකය අවශ්‍ය වේ";
    }

    if (phoneError) {
      this.setState({
        phoneError,
      });
      return false;
    }
    return true;
  };

  optTimer = () => {
    if (this.state.timeCountDown === 0) {
      clearInterval(timer);
      this.setState({ showSendOptCode: true });
    } else {
      this.setState({ timeCountDown: this.state.timeCountDown - 1 });
    }
  };

  handleTriggerOtpApiError = (response) => {
    const errorCode = response.body.code;
    switch (errorCode) {
      case 1201:
        this.setState({
          toManyAttempts: true,
          phoneError: this.props.lng
            ? "Too many attempts. Please try again later"
            : "කරුණාකර පසුව උත්සාහ කරන්න",
        });
        break;
      case 1000:
        this.setState({
          phoneError: this.props.lng
            ? "Could not send sms to this number"
            : "මෙම අංකයට කෙටි පණිවුඩ යැවීමට නොහැකිය",
        });
        break;
      case 1203:
        this.setState({
          phoneError: this.props.lng
            ? "Invalid phone number format"
            : "දුරකථන අංකය වැරදිය",
        });
        break;
      case 1204:
        this.setState({
          phoneError: this.props.lng
            ? "Can not use this phone number"
            : "Can not use this phone number",
          showBlockedUserModal: true,
        });
        break;
      case 1009:
        this.setState({
          captchaEnable: true,
          btndisable: true,
        });
        break;
      default:
        this.setState({
          phoneError: this.props.lng
            ? "Error occured"
            : "කරුණාකර පසුව උත්සාහ කරන්න",
        });
        break;
    }
    this.setState({
      showPhoneVerification: false,
    });
  };

  handleRegister = () => {
    if (this.validate()) {
      this.setState({
        loading: true,
        toManyAttempts: false,
        verificationError: false,
        sendAgainError: "",
        timeCountDown: 0,
      });
      // TODO:CLEAN_UP_AFTER_QA
      // const data = qs.stringify({
      //   userId: this.state.code + this.state.phone,
      //   idType: "PHONE",
      // });
      // let ALPHABET = "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
      // let bb = Math.round((new Date()).getTime() / 1000);
      // const tso = bb;
      // let aa = "";
      // while (bb > 0) {
      //   let tss = (ALPHABET[bb % ALPHABET.length]).toString();
      //   aa = aa+tss;
      //   bb= parseInt(bb / ALPHABET.length);
      // }

      console.log("trigger otp 2");
      AuthDomain.triggerOTP({
        number: `${this.state.code}${this.state.phone}`,
        captchaToken: this.state.token,
      })
        .then((response) => {
          if (!response.success) {
            this.handleTriggerOtpApiError(response);
            return;
          }
          timer = setInterval(this.optTimer, 1000);
          this.setState(
            {
              phoneId: response.body.id,
              timeCountDown: response.body.retryIn,
              showSendOptCode: false,
              showPhoneVerification: true,
            },
            () => {
              // scroll to top
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }
          );
        })
        .catch((error) => {
          console.log(">>>> error ", error);
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });

      // TODO:CLEAN_UP_AFTER_QA
      // axios
      //   .post(
      //     `${process.env.REACT_APP_API_URL}/v1/auth/trigger_otp`,
      //     qs.stringify({
      //       number: this.state.code + this.state.phone,
      //       webOTP: true,
      //       captchaToken: this.state.token
      //     }),
      //     {
      //       headers: {
      //         "Content-Type": "application/x-www-form-urlencoded",
      //         "x-timestamp": tso,
      //         "x-client-id": aa,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     console.log(">>>> AuthDomain.triggerOTP ", res);
      //     this.setState({
      //       phoneId: res.data.id,
      //       timeCountDown: res.data.retryIn,
      //       showSendOptCode: false,
      //       showPhoneVerification: true,
      //       loading: false,
      //     });
      //     timer = setInterval(this.optTimer, 1000);
      //     // scroll to top
      //     document.body.scrollTop = 0;
      //     document.documentElement.scrollTop = 0;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     if (err.response.data.code === 1201) {
      //       this.setState({
      //         toManyAttempts: true,
      //         phoneError: this.props.lng
      //           ? "Too many attempts. Please try again later"
      //           : "කරුණාකර පසුව උත්සාහ කරන්න",
      //       });
      //     } else if (err.response.data.code === 1000) {
      //       this.setState({
      //         phoneError: this.props.lng
      //           ? "Could not send sms to this number"
      //           : "මෙම අංකයට කෙටි පණිවුඩ යැවීමට නොහැකිය",
      //       });
      //     } else if (err.response.data.code === 1203) {
      //       this.setState({
      //         phoneError: this.props.lng
      //           ? "Invalid phone number format"
      //           : "දුරකථන අංකය වැරදිය",
      //       });
      //     } else if (err.response.data.code === 1204) {
      //       this.setState({
      //         phoneError: this.props.lng
      //           ? "Can not use this phone number"
      //           : "Can not use this phone number",
      //         showBlockedUserModal: true
      //       });
      //     } else if (err.response.data.code === 1009) {
      //       this.setState({
      //         captchaEnable: true,
      //         btndisable: true,
      //       })
      //     } else {
      //       this.setState({
      //         phoneError: this.props.lng
      //           ? "Error occured"
      //           : "කරුණාකර පසුව උත්සාහ කරන්න",
      //       });
      //     }
      //     this.setState({
      //       showPhoneVerification: false,
      //       loading: false,
      //     });
      //   });
    }
  };

  handleOPT = (code) => {
    this.setState({ optCode: code, verificationError: false });
  };

  verifyOTP = async () => {
    const nextStates = {};
    try {
      nextStates.loading = true;
      this.setState(nextStates);
      const response = await AuthDomain.verifyOTP({
        token: this.state.phoneId,
        OTPCode: this.state.optCode,
      });
      if (!response.success) {
        throw new Error("Verification code is invalid");
      }

      localStorage.setItem("auth_token", response.body.token);
      localStorage.setItem("userId", response.body.memberId);
      const authProfile = await this.props.getAuthUserProfile({
        persistToAppState: true,
      });
      if (!isEmpty(authProfile)) {
        const redirection = localStorage.getItem("redirect-after-login");
        let redirectionPath = undefined;
        if (redirection !== null) {
          const redirectionObj = JSON.parse(redirection);
          const { pathname, search } = redirectionObj;
          localStorage.removeItem("redirect-after-login");
          redirectionPath = `${pathname}${search}`;
        } else {
          redirectionPath = "/search";
        }
        this.props.setHomePageShadowedCriteria({});
        setTimeout(() => {
          this.props.initializeApp();
          this.props.history.push(redirectionPath);
        }, [500]);
      } else {
        this.props.initializeApp();
        this.props.history.push("/complete-ad-personal");
        this.props.setAppCreateFlowInitialized(false);
      }
    } catch (error) {
      nextStates.verificationError = true;
      nextStates.loading = false;
      console.log(">>> [Error]", error);
    } finally {
      this.setState(nextStates);
    }
  };

  // TODO:CLEAN_UP_AFTER_QA
  // verify = () => {
  //   this.setState({ loading: true });

  //   let ALPHABET =
  //     "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
  //   let bb = Math.round(new Date().getTime() / 1000);
  //   const tso = bb;
  //   let aa = "";
  //   while (bb > 0) {
  //     let tss = ALPHABET[bb % ALPHABET.length].toString();
  //     aa = aa + tss;
  //     bb = parseInt(bb / ALPHABET.length);
  //   }

  //   const utmCookieKeys = ["utm_campaign", "utm_medium", "utm_source"];
  //   const utmCookies = extractUtmCookies(utmCookieKeys);

  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}/v1/auth/login_otp`,
  //       qs.stringify({
  //         // phoneId: this.state.phoneId,
  //         token: this.state.phoneId,
  //         code: this.state.optCode,
  //         ...utmCookies,
  //       }),
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       localStorage.setItem("auth_token", res.data.token);
  //       localStorage.setItem("userId", res.data.memberId);
  //       axios
  //         .get(`${process.env.REACT_APP_API_URL}/v1/account`, {
  //           headers: {
  //             Authorization: `Bearer ${res.data.token}`,
  //           },
  //         })
  //         .then((res) => {
  //           localStorage.setItem(
  //             "user_name",
  //             res.data.name ? res.data.name : ""
  //           );
  //           localStorage.setItem("user_role", res.data.role);
  //           if (
  //             res.data.role === "OPERATOR" ||
  //             res.data.role === "OPERATOR_L2"
  //           ) {
  //             this.props.history.push("/manage-posts");
  //           } else {
  //             if (
  //               res.data.role === "OPERATOR" ||
  //               res.data.role === "OPERATOR_L2"
  //             ) {
  //               this.props.history.push("/manage-posts");
  //             } else {
  //               let url = "";
  //               if (
  //                 localStorage.getItem("user_role") === "OPERATOR" ||
  //                 localStorage.getItem("user_role") === "OPERATOR_L2"
  //               ) {
  //                 url = `operator/managed_posts/${localStorage.getItem(
  //                   "postId"
  //                 )}`;
  //               } else {
  //                 url = "account/posts";
  //               }

  //               axios
  //                 .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
  //                   headers: {
  //                     "Content-Type": "application/json",
  //                     Authorization: `Bearer ${localStorage.getItem(
  //                       "auth_token"
  //                     )}`,
  //                   },
  //                 })
  //                 .then(async (res) => {
  //                   if (
  //                     localStorage.getItem("user_role") === "OPERATOR" ||
  //                     localStorage.getItem("user_role") === "OPERATOR_L2"
  //                   ) {
  //                     res.data[0] = res.data;
  //                   }
  //                   if (res.data.length > 0) {
  //                     localStorage.setItem(
  //                       "post_details",
  //                       JSON.stringify(res.data[0])
  //                     );
  //                     localStorage.setItem("postId", res.data[0].id);
  //                     this.props.fetchAllInterest();
  //                     if (
  //                       this.props.history.location.pathname === "/register" ||
  //                       this.props.history.location.pathname === "/auth"
  //                     ) {
  //                       const redirection = localStorage.getItem(
  //                         "redirect-after-login"
  //                       );
  //                       let redirectionPath = undefined;
  //                       if (redirection !== null) {
  //                         const redirectionObj = JSON.parse(redirection);
  //                         const { pathname, search } = redirectionObj;
  //                         localStorage.removeItem("redirect-after-login");
  //                         redirectionPath = `${pathname}${search}`;
  //                       } else {
  //                         redirectionPath = "/search";
  //                       }
  //                       this.props.setHomePageShadowedCriteria({});
  //                       await this.props.initializeApp();
  //                       setTimeout(() => {
  //                         this.props.history.push(redirectionPath);
  //                       }, [500]);
  //                     } else {
  //                       window.location.reload();
  //                     }
  //                     this.setState({ loadingLogin: false });
  //                   } else {
  //                     axios
  //                       .get(
  //                         `${process.env.REACT_APP_API_URL}/v1/account/posts/temp`,
  //                         {
  //                           headers: {
  //                             Authorization: `Bearer ${localStorage.getItem(
  //                               "auth_token"
  //                             )}`,
  //                           },
  //                         }
  //                       )
  //                       .then(async (res) => {
  //                         localStorage.removeItem("personalInfo");
  //                         localStorage.removeItem("parentInfo");
  //                         localStorage.removeItem("privateInfo");

  //                         if (res.data !== "") {
  //                           if (res.data.postData.personalInfo) {
  //                             localStorage.setItem(
  //                               "personalInfo",
  //                               JSON.stringify(res.data.postData.personalInfo)
  //                             );
  //                           }

  //                           if (
  //                             !localStorage.getItem("user_name") &&
  //                             res.data.postData.personalInfo &&
  //                             res.data.postData.personalInfo.fname
  //                           ) {
  //                             localStorage.setItem(
  //                               "user_name",
  //                               res.data.postData.personalInfo.fname
  //                             );
  //                           }

  //                           if (res.data.postData.parentInfo) {
  //                             localStorage.setItem(
  //                               "parentInfo",
  //                               JSON.stringify(res.data.postData.parentInfo)
  //                             );
  //                           }

  //                           if (
  //                             res.data.postData.horoscopeMatching ||
  //                             res.data.postData.makePhonePublic
  //                           ) {
  //                             localStorage.setItem(
  //                               "privateInfo",
  //                               JSON.stringify({
  //                                 phoneId: res.data.postData.phoneId,
  //                                 makePhonePublic:
  //                                   res.data.postData.makePhonePublic,
  //                                 horoscopeMatching:
  //                                   res.data.postData.horoscopeMatching,
  //                                 birthTime: res.data.postData.birthTime,
  //                                 birthCity: res.data.postData.birthCity,
  //                                 images: res.data.postData.images,
  //                                 phone: res.data.postData.phone,
  //                                 horoscopeDetail: res.data.postData
  //                                   .horoscopeDetail
  //                                   ? res.data.postData.horoscopeDetail
  //                                   : "",
  //                                 horoscopeImages: res.data.postData
  //                                   .horoscopeImages
  //                                   ? res.data.postData.horoscopeImages
  //                                   : [],
  //                               })
  //                             );
  //                           }
  //                         }

  //                         await this.props.initializeApp();
  //                         setTimeout(() => {
  //                           // this.props.history.push("/complete-ad-review");
  //                           this.props.history.push("/complete-ad-personal");
  //                         }, [500]);
  //                       })
  //                       .catch((err) => console.log(err));
  //                   }
  //                 })
  //                 .catch((err) => {
  //                   console.log(err);
  //                   this.setState({ loadingLogin: false });
  //                 });
  //             }
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     })
  //     .catch((err) => {
  //       this.setState({
  //         verificationError: true,
  //         loading: false,
  //       });
  //     });
  // };

  // login when press the enter key
  handleRegisterKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("user-register-btn").click();
    }
  };

  handleClose = () => {
    this.setState({ errorAlert: false });
  };

  submitRecaptcha = (event) => {
    event.preventDefault();
    // if(this.state.captchaEnable === false){
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(this.state.captchaSecret, { action: "contact" })
        .then((token) => {
          this.setState({
            token: token,
          });
          this.handleRegister();
        });
    });
  };

  handleRecaptcha = () => {
    this.setState({
      btndisable: true,
    });
  };

  blockedUserModalClose = () => {
    this.setState({ showBlockedUserModal: false });
  };

  render() {
    const filteredCountries = this.props.countries.filter((country) => {
      return (
        country.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1 || country.dialCode.includes(this.state.search)
      );
    });

    return (
      <React.Fragment>
        {!this.state.showPhoneVerification ? (
          <div
            className="create-login-div auth-login-div"
            style={{ margin: 0 }}
          >
            {this.state.showCountryCodes ? null : (
              <React.Fragment>
                <p
                  className="modal-heading"
                  style={{ textAlign: "center", border: 0, marginBottom: 50 }}
                >
                  {this.props.lng ? (
                    "Continue with Phone"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-17">
                      දුරකථන අංකයෙන් පිවිසෙන්න
                    </span>
                  )}
                </p>
              </React.Fragment>
            )}
            {this.state.authError ? (
              <p
                style={{
                  color: "#f44336",
                  fontSize: "0.75rem",
                  marginTop: 0,
                }}
              >
                {this.state.authError}
              </p>
            ) : null}
            <React.Fragment>
              {!this.state.showCountryCodes && (
                <p className="input-headings">
                  {this.props.lng ? (
                    "Phone Number"
                  ) : (
                    <span className="sinhala-size-12">දුරකතන අංකය</span>
                  )}
                </p>
              )}
              {this.state.showCountryCodes ? (
                <React.Fragment>
                  <p
                    className="login-signup-back"
                    onClick={() => this.setState({ showCountryCodes: false })}
                  >
                    <i className="fas fa-chevron-left"></i>{" "}
                    {this.props.lng === true ? (
                      "BACK"
                    ) : (
                      <span className="sinhala-size-12">ආපසු යන්න</span>
                    )}
                  </p>
                  <p className="modal-heading" style={{ border: 0 }}>
                    {this.props.lng ? (
                      "Select Phone Dialing Code"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-17">
                        දුරකථන ඇමතුම් කේතය තෝරන්න
                      </span>
                    )}
                  </p>
                  <div style={{ position: "relative" }}>
                    <input
                      autoFocus
                      type="text"
                      name="search"
                      className="search-county-code-input"
                      placeholder="Search"
                      value={this.state.search}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <i className="fas fa-search code-search-icon"></i>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <input
                    className="phone-input-country-code"
                    onClick={() => this.setState({ showCountryCodes: true })}
                    value={this.state.code}
                  />
                  <input
                    type="number"
                    name="phone"
                    className={
                      this.state.phoneError
                        ? "phone-input-custom red-border"
                        : "phone-input-custom"
                    }
                    value={this.state.phone}
                    onChange={this.handlePhone}
                    autoComplete="off"
                  />
                  <div className="error-handler">{this.state.phoneError} </div>
                </React.Fragment>
              )}
              {this.state.showCountryCodes && (
                <div className="country-code-div">
                  {filteredCountries.map((code, i) => (
                    <p
                      key={i}
                      onClick={() => this.handleCountryCode(code.dialCode)}
                    >
                      <span>{code.name}</span> <span>{code.dialCode}</span>
                    </p>
                  ))}
                </div>
              )}
            </React.Fragment>
            {this.state.showFirstView || this.state.showCountryCodes ? null : (
              <React.Fragment>
                {/* {!this.state.captchaEnable && 
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY_V2}
                    onChange={this.handleRecaptcha}
                    style={{marginTop: "10px"}}
                  />
                } */}
                <Button
                  variant="contained"
                  className="login-btn g-recaptcha"
                  onClick={(e) => this.submitRecaptcha(e)}
                  id="user-register-btn"
                  data-sitekey={this.state.captchaSecret}
                  // disabled = {!this.state.btndisable}
                >
                  {this.state.loading ? (
                    <React.Fragment>
                      <CircularProgress size={16} style={{ color: "#000" }} />{" "}
                    </React.Fragment>
                  ) : this.props.lng ? (
                    "Continue"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-14">
                      ඉදිරියට
                    </span>
                  )}
                </Button>
                {this.state.toManyAttempts && (
                  <p
                    style={{
                      fontSize: 12,
                      color: "#f44336",
                      marginRight: 12,
                      marginBottom: 0,
                      textAlign: "center",
                    }}
                  >
                    {this.props.lng
                      ? this.state.timeCountDown !== 0
                        ? `* Too many attempts. Please try again in 
                            ${this.state.timeCountDown} seconds.`
                        : ""
                      : this.state.timeCountDown !== 0
                      ? `* කරුණාකර තත්පර ${this.state.timeCountDown} කින් නැවත උත්සාහ කරන්න.`
                      : ``}
                  </p>
                )}
                <span
                  className="create-account-link"
                  onClick={() => this.props.history.push("/login")}
                  style={{ cursor: "pointer", marginTop: "-5px" }}
                >
                  {this.props.lng ? (
                    <span>Login using password</span>
                  ) : (
                    <span className="sinhala-size-10">
                      මුරපදය භාවිතයෙන් පිවිසෙන්න
                    </span>
                  )}
                </span>
                <p
                  className="back"
                  onClick={() => this.props.history.push("/")}
                  style={{ marginBottom: "-50px" }}
                >
                  {this.props.lng ? "< BACK" : "< ආපසු යන්න"}
                </p>
                {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK ? (
                  <CallUsToCreateAccount lng={this.props.lng} />
                ) : null}
              </React.Fragment>
            )}
          </div>
        ) : (
          <div
            className="create-login-div auth-login-div"
            style={{ margin: 0 }}
          >
            <p
              className="modal-heading"
              style={{ textAlign: "center", border: 0, marginBottom: 25 }}
            >
              {this.props.lng ? (
                "Verify Phone Number"
              ) : (
                <span className="sinhala-w-600 sinhala-size-17">
                  දුරකථන අංකය තහවුරු කරන්න
                </span>
              )}
            </p>
            <p className="your-phone">
              {this.props.lng ? "Your Phone Number" : "ඔබගේ දුරකථන අංකය"}
            </p>
            <p className="phone">
              {this.state.code} {this.state.phone}
            </p>
            <p
              className="change"
              onClick={() => this.setState({ showPhoneVerification: false })}
            >
              {this.props.lng ? "(Change)" : "වෙනස් කරන්න"}
            </p>
            <p className="enter-code">
              {this.props.lng ? "Enter Code" : "කේතය ඇතුලත් කරන්න"}{" "}
            </p>
            <ReactCodeInput
              fields={6}
              onChange={(val) => this.handleOPT(val)}
              autocomplete="one-time-code"
              values={this.state.getAuotOtp ? [1, 2, 3, 4, 5, 6] : null}
            />
            <p className="opt-error" style={{ textAlign: "center" }}>
              {this.state.verificationError
                ? this.props.lng
                  ? "Verification code is invalid"
                  : "කේතය වලංගු නොවේ"
                : null}
            </p>
            <p className="opt">
              {this.props.lng ? "Didn't receive OTP?" : "OTP ලැබුණේ නැද්ද?"}
            </p>
            <p className="opt" style={{ marginTop: 2 }}>
              {this.state.showSendOptCode ? (
                <span
                  onClick={(e) => this.submitRecaptcha(e)}
                  style={{ cursor: "pointer" }}
                >
                  {this.props.lng ? (
                    "Send again"
                  ) : (
                    <span className="sinhala-w-600">නැවත යවන්න</span>
                  )}
                </span>
              ) : (
                <span style={{ textDecoration: "none" }}>
                  {this.props.lng ? "Retry in" : "තත්පර"}{" "}
                  {this.state.timeCountDown}
                  {this.props.lng ? "s" : "කින් පසුව උත්සහ කරන්න"}
                </span>
              )}
            </p>
            {this.state.sendAgainError && (
              <p
                style={{
                  fontSize: 12,
                  color: "#f44336",
                  marginRight: 12,
                  marginTop: 5,
                }}
              >
                * {this.state.sendAgainError}
              </p>
            )}
            <Button
              variant="contained"
              className="login-btn"
              onClick={this.verifyOTP}
            >
              {this.state.loading ? (
                <React.Fragment>
                  <CircularProgress size={16} style={{ color: "#000" }} />
                </React.Fragment>
              ) : (
                <span>
                  {this.props.lng ? (
                    "Login"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-13">
                      "පිවිසෙන්න"
                    </span>
                  )}
                </span>
              )}
            </Button>
            <p
              className="back"
              onClick={() => this.setState({ showPhoneVerification: false })}
            >
              {this.props.lng ? "< BACK" : "< ආපසු යන්න"}
            </p>
          </div>
        )}
        <ComErrorAlert
          lng={this.props.lng}
          errorAlert={this.state.errorAlert}
          errorCode={this.state.errorCode}
          handleClose={this.handleCloseAlert}
        />
        <BlockedUserModal
          lng={this.props.lng}
          showBlockedUserModal={this.state.showBlockedUserModal}
          blockedUserModalClose={this.blockedUserModalClose}
        />
      </React.Fragment>
    );
  }
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAllInterest: fetchAllInterest,
      handleIntBtns: handleIntBtns,
      setHomePageShadowedCriteria,
      initializeApp,
      getAuthUserProfile,
      getAuthTempProfile,
      setAppCreateFlowInitialized,
    },
    dispatch
  );
};
const mapStateToProps = (state) => {
  return {
    matchedPosts: state.matches.allInterests.matches
      ? state.matches.allInterests.matches.filter((el) => !el.ignored)
      : [],
    receivedPosts: state.matches.allInterests.received
      ? state.matches.allInterests.received
      : [],
    appConfig: state.application.appConfig,
    appInitialized: state.application.initialized,
    authAuthenticated: state.application.authenticated,
    authAccount: state.application.authAccount,
    authProfile: state.application.authProfile,
    authTempProfile: state.application.authTempProfile,
    didCompletedCreateflow: state.application.createflowInitialized,
    countries: state.genericData.secondaryCountries,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(RegisterAcc));

// export default withRouter(RegisterAcc);
