import { Grid, Typography } from "@material-ui/core";
import React from "react";

const IconText = ({ text, icon }) => {
  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      xs={4}
      className="row-gap-8"
    >
      <img src={icon} alt="usp" className="usp-icon" />
      <Typography className="usp-item-txt">
        {text}
      </Typography>
    </Grid>
  );
};

export default IconText;
