import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import "../../style.css";
import FormDropdown from "../../../../components/molecules/form-dropdown";
import { ACCOUNT_CREATED_BY_OPTIONS } from "../../../../static-data";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import FormTextInput from "../../../../components/molecules/form-text-input";
import { postVipRequest } from "../../../../features/generic-data";
import { setAppSnackNotification } from "../../../../store/actions/application";
import { useDispatch } from "react-redux";
import FormMeta from "./form-meta";

const InquiryForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    accountOwner: "",
  };

  const { t, i18n } = useTranslation();

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.yourName") })
    ),
    phoneNumber: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("common.contactNumber"),
      })
    ),
    email: Yup.string()
      .optional()
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        t("errors.invalidFieldValue", { fieldName: t("common.email") })
      ),
  });

  const onSubmitFields = async ({ values, actions }) => {
    try {
      const response = await postVipRequest({ formData: values });

      if (response.success) {
        actions.resetForm();
        setIsSubmitted(true);
        dispatch(
          setAppSnackNotification({
            severity: "success",
            message: "Submitted successfully!",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Submission failed. Please try again.",
        })
      );
      console.log("Error", error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      onSubmit={(values, actions) => onSubmitFields({ values, actions })}
    >
      {({ values, errors, handleChange, handleSubmit, submitCount }) => (
        <Grid
          id="premium-inquiry-form"
          container
          direction="column"
          alignItems="center"
          className="premium-inquiry-form-container"
        >
          <Typography variant="h5" className="contact-title">
            CONNECT WITH US
          </Typography>
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            className="contact-form"
            xs={12}
            sm={8}
            md={6}
            lg={4}
          >
            <FormTextInput
              fieldName={FormMeta.name.fieldName}
              variant="outlined"
              className="contact-form-txt-field"
              placeholder={FormMeta.name.placeHolder}
              value={values[FormMeta.name.fieldName]}
              errorText={submitCount > 0 && errors.name}
              showLabel={false}
              hideCharactorLength={true}
              onChange={handleChange}
            />
            <FormTextInput
              fieldName={FormMeta.phoneNumber.fieldName}
              variant="outlined"
              className="contact-form-txt-field"
              placeholder={FormMeta.phoneNumber.placeHolder}
              value={values[FormMeta.phoneNumber.fieldName]}
              inputType={FormMeta.phoneNumber.inputType}
              errorText={submitCount > 0 && errors.phoneNumber}
              showLabel={false}
              hideCharactorLength={true}
              onChange={handleChange}
            />
            <FormTextInput
              fieldName={FormMeta.email.fieldName}
              variant="outlined"
              className="contact-form-txt-field"
              placeholder={FormMeta.email.placeHolder}
              value={values[FormMeta.email.fieldName]}
              errorText={submitCount > 0 && errors.email}
              showLabel={false}
              hideCharactorLength={true}
              onChange={handleChange}
            />
            <FormDropdown
              fieldName={FormMeta.accountOwner.fieldName}
              options={ACCOUNT_CREATED_BY_OPTIONS}
              keyExtractor={FormMeta.accountOwner.keyExtractor}
              labelExtractor={FormMeta.accountOwner.labelExtractor}
              value={values[FormMeta.accountOwner.fieldName]}
              lang={i18n.language}
              errorText={submitCount > 0 && errors.accountOwner}
              onChange={handleChange}
              containerStyles={{ width: "100%" }}
              selectLabel={t(FormMeta.accountOwner.selectLabel)}
            />
            <Button
              type="submit"
              className="contact-form-submit-btn"
              onClick={handleSubmit}
              disabled={isSubmitted}
              style={{ width: "40%" }}
            >
              Submit
            </Button>
          </Grid>
          <Typography variant="h5" className="notice-txt accent-font">
            Premium matchmaking with poruwa.lk
          </Typography>
        </Grid>
      )}
    </Formik>
  );
};

export default InquiryForm;
