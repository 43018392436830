import { Grid, Switch, Typography } from "@material-ui/core";
import React from "react";

const PreferenceToggle = ({ id, label, value, handleChange }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      xs={12}
      sm={6}
    >
      <Typography variant="caption" className="preferences-toggle-txt">
        {label}
      </Typography>
      <Switch id={id} checked={value} onChange={handleChange} />
    </Grid>
  );
};

export default PreferenceToggle;
