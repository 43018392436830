import {
  Button,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import UspView from "./templates/usp-view";
import InquiryForm from "./templates/inquiry-form";
import { scrollToTop } from "../../utils";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import withLanguageMigrator from "../../hoc/language-migrator";
import CallIcon from "@material-ui/icons/Call";
import "./style.css";
import FeaturesView from "./templates/features-view";
import RouteConfigHandler from "../../templates/route-config-handler";

const PremiumPackageLandingPage = ({ routeId, lng }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    scrollToTop();
  }, []);

  const onClckInquireNow = () => {
    const element = document.getElementById("premium-inquiry-form");

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  return (
    <RouteConfigHandler id={routeId}>
      <Grid container direction="column" className="premium-container">
        <Grid
          container
          direction="column"
          alignItems="center"
          className="hero-section"
        >
          <Grid xs item container className="hero-image">
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              className="hero-content"
            >
              <img
                src="https://static.poruwa.lk/images/payment/premium-logo.png"
                alt="Premium Logo"
                className="hero-content-image"
              />
              <Typography
                variant={`${isMobileView ? "h5" : "h4"}`}
                className="primary-txt"
              >
                Matchmaking with Extra Care
              </Typography>
              <Button className="inquire-btn" onClick={onClckInquireNow}>
                Inquire Now
              </Button>
              <Link href="tel:+94112824814">
                <Typography variant="body1" className="contact-num-txt">
                  <CallIcon />
                  0112 824 814
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <UspView />
        </Grid>
        <FeaturesView />
        <InquiryForm />
      </Grid>

      {isMobileView ? <FooterMobile lng={lng} /> : <Footer lng={lng} />}
    </RouteConfigHandler>
  );
};

export default withLanguageMigrator(PremiumPackageLandingPage);
